import { Grid } from "@material-ui/core";
import { ColDef } from "ag-grid-community";
import { useEffect, useMemo, useRef, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { dxtToLocalServerTime, getMomentTz } from "@dexteel/mesf-core";
import { AgGridReact } from "ag-grid-react";
import { useGridStyles } from "../../styles/gridStyles";
import { LadleStatus } from "../models/LadleStatus";
import { StatusIndicator } from "./status-indicator";

type Props = {
	ladleStatus: LadleStatus[];
};

export const TableLadleStatus = ({ ladleStatus }: Props) => {
	const gridRef = useRef<AgGridReact>(null);
	const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
	const classes = useGridStyles();

	const formatTimeFromSeconds = (totalSeconds: number): string => {
		if (!totalSeconds) return "";

		const days = Math.floor(totalSeconds / (3600 * 24));
		const remainingSeconds = totalSeconds % (3600 * 24);
		const hours = Math.floor(remainingSeconds / 3600);
		const minutes = Math.floor((remainingSeconds % 3600) / 60);
		const seconds = remainingSeconds % 60;

		if (days > 0) {
			return `${days}d ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
		}

		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
	};

	const getStatusData = (params: any) => {
		if (!params.data.LastTappedDateTime) return { lastTappedDate: null };

		const now = getMomentTz()();
		const lastTapped = getMomentTz()(params.data.LastTappedDateTime);
		const diffDays = now.diff(lastTapped, "days");

		return {
			lastTappedDate: params.data.LastTappedDateTime,
			diffDays: diffDays,
		};
	};

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			sortable: true,
			wrapText: true,
			autoHeight: true,
			wrapHeaderText: true,
		};
	}, []);

	useEffect(() => {
		setColumnDefs([
			{
				field: "status",
				headerName: "Status",
				minWidth: 70,
				maxWidth: 70,
				cellRenderer: StatusIndicator,
				valueGetter: getStatusData,
				suppressMenu: true,
				sortable: false,
				cellStyle: { padding: "0px" },
			},
			{
				field: "LadleName",
				headerName: "Ladle",
				minWidth: 100,
				maxWidth: 100,
				flex: 2,
			},
			{
				field: "HeatCount",
				headerName: "Total Heats",
				minWidth: 100,
				flex: 1,
			},
			{
				field: "TotalContactTime",
				headerName: "Total Contact",
				minWidth: 120,
				flex: 1,
				valueFormatter: (params) => formatTimeFromSeconds(params.value),
			},
			{
				field: "FirstHeatName",
				headerName: "First Heat Number",
				minWidth: 140,
				flex: 1,
			},
			{
				field: "FirstHeatDateTime",
				headerName: "First Heat Date",
				minWidth: 140,
				flex: 3,
				valueFormatter: (params) =>
					dxtToLocalServerTime(params.value, "MM/dd/yy HH:mm:ss"),
			},
			{
				field: "LastTappedHeatName",
				headerName: "Last Tapped Heat Number",
				minWidth: 180,
				flex: 1,
			},
			{
				field: "LastTappedDateTime",
				headerName: "Last Tap Date",
				minWidth: 140,
				flex: 3,
				valueFormatter: (params) =>
					dxtToLocalServerTime(params.value, "MM/dd/yy HH:mm:ss"),
			},
		]);
	}, []);

	return (
		<Grid
			container
			className={classes.root}
			style={{ width: "100%", display: "flex" }}
		>
			<Grid
				item
				md={12}
				xs={12}
				style={{
					height: "78vh",
					minHeight: "37vh",
					width: "100%",
					border: "4px solid #ccc",
					borderRadius: 10,
					padding: 10,
					margin: "20px 0 10px",
				}}
				className={"ag-theme-balham"}
			>
				<AgGridReact
					ref={gridRef}
					rowData={ladleStatus}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					rowHeight={33}
					headerHeight={32}
					animateRows={true}
					suppressContextMenu={true}
					pagination={true}
					suppressRowHoverHighlight={true}
				/>
			</Grid>
		</Grid>
	);
};
