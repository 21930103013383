import { DelayCode } from "@/pages/delays/models/Delay";
import { ErrorModal } from "@dexteel/mesf-core";
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Snackbar,
	Typography,
	makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { Alert } from "@material-ui/lab";
import { Tree } from "@minoru/react-dnd-treeview";
import {
	DragLayerMonitorProps,
	DropOptions,
	NodeModel,
} from "@minoru/react-dnd-treeview/dist/types";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import { FileProperties } from "../../../models/FileProperties";
import { useConfigurationDelayCodeContext } from "../../context/ConfigurationDelayCodeContext";
import { useSearchDelays } from "../../hooks/useSearchDelays";
import { CustomNode } from "../CustomNode";
import { CustomDragPreview } from "../DragPreview/CustomDragPreview";
import { MultipleDragPreview } from "../DragPreview/MultipleDragPreview";
import { useDelayActions } from "../hooks/useDelayActions";
import { CreateDelay } from "../modals/CreateDelay";
import { DeleteDelay } from "../modals/DeleteDelay";
import { EditDelay } from "../modals/EditDelay";
import { ViewDelay } from "../modals/ViewDelay";

const useStyles = makeStyles((theme) => ({
	root: {},
	contextMenu: {
		backgroundColor: "#F8F8F8",
		borderRadius: "4px",
		padding: "0",
		height: "auto",
		width: "170px",
		margin: "0",
		position: "fixed",
		listStyle: "none",
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
		zIndex: 1000,
	},
	menuItem: {
		padding: "6px 12px 6px 10px",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		backgroundColor: "#F8F8F8",
		fontSize: "12px",
		fontFamily:
			'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
		"&:last-child": {
			borderBottom: "none",
		},
		"&:hover": {
			backgroundColor: "#d4edff",
		},
	},
	storyRoot: {
		display: "grid",
		height: "100%",
		gridTemplateRows: "auto 1fr",
		listStyleType: "none !important",
	},

	assetRoot: {
		boxSizing: "border-box",
		height: "100%",
		padding: "32px",
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: 20,
	},
	dropTarget: {
		boxShadow: "0 0 0 1px #1967d2 inset",
	},
}));

interface TreeViewDelay {
	delayAreaAssetId?: number;
	delayAreaAssetName?: string;
}

export const TreeViewDelay = ({
	delayAreaAssetId,
	delayAreaAssetName,
}: TreeViewDelay) => {
	const [selectedNodes, setSelectedNodes] = useState<
		NodeModel<FileProperties>[]
	>([]);
	const classes = useStyles();
	const [isDragging, setIsDragging] = useState(false);
	const [isCtrlPressing, setIsCtrlPressing] = useState(false);
	const [isDoubleClick, setIsDoubleClick] = useState(false);
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [showViewModal, setShowViewModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [errorUpdate, setErrorUpdate] = useState<string>("");
	const [searchError, setSearchError] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [updatedSuccessfullyMessage, setUpdatedSuccessfullyMessage] =
		useState(false);

	const {
		state: {
			allDelayCodes,
			allDelayNodes,
			openDelays,
			showContextMenu,
			showCreateNewContextMenu,
			delayNodeSelectedInTree,
			anchorPoint: { x, y },
		},
		actions: {
			setShowContextMenu,
			setShowCreateNewContextMenu,
			setMenuContextDelayId,
			setAnchorPointX,
			setAnchorPointY,
			setDelayNodeSelectedInTree,
			setDelayAreaAsset,
		},
	} = useConfigurationDelayCodeContext();

	const { searchDelays } = useSearchDelays({
		setSearchError,
		setAllDelayLoading: setIsLoading,
	});

	const { handleDrop, handleCtrlClick, handleDragStart, handleDragEnd } =
		useDelayActions({
			delay: allDelayNodes,
			selectedNodes,
			setSelectedNodes,
			setIsDragging,
			isCtrlPressing,
			setIsCtrlPressing,
			onDelayUpdateStart: () => {
				setIsLoading(true);
			},
			onDelayUpdateEnd: (result) => {
				if (result.ok) {
					setUpdatedSuccessfullyMessage(true);
					searchDelays(delayAreaAssetId);
				} else {
					setErrorUpdate(result.message);
				}
			},
		});

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setUpdatedSuccessfullyMessage(false);
	};

	const handleClick = (e: SyntheticEvent) => {
		if (showContextMenu) {
			setShowContextMenu(false);
			setMenuContextDelayId(undefined);
		}
		if (showCreateNewContextMenu) {
			setShowCreateNewContextMenu(false);
			setMenuContextDelayId(undefined);
		}
	};

	const handleCreateNewContextMenu = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setShowContextMenu(false);
		const top = e.clientY;
		const left = e.clientX;

		setAnchorPointX(left);
		setAnchorPointY(top);
		setShowCreateNewContextMenu(true);
		setDelayNodeSelectedInTree(undefined);
	};

	const handleContextMenu = (
		data: NodeModel<FileProperties>,
		e: React.MouseEvent<HTMLElement>,
	) => {
		e.preventDefault();
		setShowCreateNewContextMenu(false);
		const top = e.clientY;
		const left = e.clientX;

		setAnchorPointX(left);
		setAnchorPointY(top);

		setSelectedNodes([data]);

		setShowContextMenu(true);
		const selectedNode = allDelayCodes.find(
			(node: DelayCode) => node.DelayCodeId === data.id,
		);
		if (selectedNode) {
			setDelayNodeSelectedInTree(selectedNode);
		}
	};

	const handleShowViewerDelay = (
		data: NodeModel<FileProperties>,
		e: React.MouseEvent<HTMLElement>,
	) => {
		e.preventDefault();
		setIsDoubleClick(true);
		const selectedNode = allDelayCodes.find(
			(node: DelayCode) => node.DelayCodeId === data.id,
		);
		if (selectedNode) {
			setDelayNodeSelectedInTree(selectedNode);
			setShowViewModal(true);
		}
	};

	const handleOnHideViewer = () => {
		setShowViewModal(false);
		setIsDoubleClick(false);
		setShowCreateNewContextMenu(false);
		setShowContextMenu(false);
	};

	useEffect(() => {
		const delayAreaAsset = {
			AssetId: delayAreaAssetId,
			AssetName: delayAreaAssetName,
		};
		setDelayAreaAsset(delayAreaAsset);
		searchDelays(delayAreaAssetId);
	}, []);

	const openDelaysArray = useMemo(() => {
		return Object.keys(openDelays).map((el) => parseInt(el));
	}, [openDelays]);

	if (!isLoading)
		return (
			<div style={{ width: "100%", height: "100%" }}>
				<div onClick={handleClick}>
					<div onContextMenu={handleCreateNewContextMenu}>
						<div>
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								className={classes.root}
							>
								<Paper
									elevation={1}
									style={{
										width: "100%",
										padding: "15px 20px 25px",
										borderRadius: "0.75rem",
									}}
								>
									<Typography
										variant="h5"
										style={{ margin: "20px 0 20px", fontWeight: 600 }}
									>
										Delay Codes Tree{" "}
										{delayAreaAssetName ? `- ${delayAreaAssetName}` : ""}
									</Typography>
									{isLoading ? (
										<Grid container style={{ width: "100%", height: "100%" }}>
											<div
												style={{
													width: "100%",
													height: "100%",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<LazyLoading />
											</div>
										</Grid>
									) : (
										<Paper
											elevation={2}
											style={{
												width: "100%",
												padding: "15px 20px 10px",
												borderRadius: "0.75rem",
											}}
										>
											<Grid container alignItems="center">
												<Grid
													container
													style={{
														height: "60vh",
														overflowY: "auto",
														paddingBottom: 10,
													}}
												>
													<Grid>
														<Tree
															rootId={0}
															initialOpen={openDelaysArray}
															tree={allDelayNodes}
															enableAnimateExpand={true}
															sort={false}
															classes={{
																root: classes.assetRoot,
																dropTarget: classes.dropTarget,
															}}
															onDrop={handleDrop}
															onDragStart={handleDragStart}
															onDragEnd={handleDragEnd}
															canDrop={(
																dcode: NodeModel<FileProperties>[],
																options: DropOptions<FileProperties>,
															): boolean => {
																return !selectedNodes.some(
																	(selectedNode) =>
																		selectedNode.id === options.dropTargetId,
																);
															}}
															render={(node, options) => {
																const selected = selectedNodes.some(
																	(selectedNode) => selectedNode.id === node.id,
																);
																return (
																	<CustomNode
																		setContextMenuOver={handleContextMenu}
																		node={node}
																		{...options}
																		isSelected={selected}
																		isDragging={selected && isDragging}
																		onClick={handleCtrlClick}
																		onDoubleClick={handleShowViewerDelay}
																	/>
																);
															}}
															dragPreviewRender={(
																monitorProps: DragLayerMonitorProps<FileProperties>,
															) => {
																if (selectedNodes.length > 1) {
																	return (
																		<MultipleDragPreview
																			dragSources={selectedNodes}
																		/>
																	);
																}
																return (
																	<CustomDragPreview
																		monitorProps={monitorProps}
																	/>
																);
															}}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Paper>
									)}
									<CreateDelay
										show={showCreateModal}
										onHide={(shouldUpdate: boolean) => {
											setShowCreateModal(false);
											if (shouldUpdate) {
												searchDelays(delayAreaAssetId);
											}
										}}
									/>
									<EditDelay
										show={showEditModal}
										onHide={(shouldUpdate) => {
											setShowEditModal(false);
											if (shouldUpdate) {
												searchDelays(delayAreaAssetId);
											}
										}}
									/>
									<ViewDelay
										show={showViewModal}
										onHide={() => handleOnHideViewer()}
									/>
									<DeleteDelay
										show={showDeleteModal}
										onHide={(shouldUpdate) => {
											setShowDeleteModal(false);
											if (shouldUpdate) {
												searchDelays(delayAreaAssetId);
											}
										}}
									/>
									<Grid container justifyContent="flex-end">
										<Grid
											item
											md={3}
											sm={4}
											xs={12}
											className={classes.btnModal}
										>
											<Button
												variant="contained"
												color="primary"
												fullWidth
												style={{ margin: "0px 15px" }}
												onClick={() => setShowCreateModal(!showCreateModal)}
											>
												NEW DELAY CODE
											</Button>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
							<Snackbar
								open={updatedSuccessfullyMessage}
								autoHideDuration={2500}
								onClose={handleClose}
							>
								<Alert severity="success" onClose={handleClose}>
									Delay updated successfully
								</Alert>
							</Snackbar>
						</div>
						{showContextMenu && !isDoubleClick ? (
							<ul
								className={classes.contextMenu}
								style={{ top: y, left: x, padding: "6px 0" }}
							>
								<MenuItem
									className={classes.menuItem}
									onClick={() => setShowViewModal(true)}
								>
									<Grid container style={{ alignItems: "center" }}>
										<Grid item md={2} xs={3}>
											<FindInPageIcon style={{ width: 18 }} />
										</Grid>
										<Grid item md={9} xs={9}>
											<div style={{ marginLeft: 5 }}>View Delay Code</div>
										</Grid>
									</Grid>
								</MenuItem>
								<MenuItem
									className={classes.menuItem}
									onClick={() => setShowEditModal(true)}
								>
									<Grid container style={{ alignItems: "center" }}>
										<Grid item md={2} xs={3}>
											<EditIcon style={{ width: 18 }} />
										</Grid>
										<Grid item md={9} xs={9}>
											<div style={{ marginLeft: 5 }}>Edit Delay Code</div>
										</Grid>
									</Grid>
								</MenuItem>
								<MenuItem
									className={classes.menuItem}
									onClick={() => setShowCreateModal(true)}
								>
									<Grid container style={{ alignItems: "center" }}>
										<Grid item md={2} xs={3}>
											<PlaylistAddIcon style={{ width: 18 }} />
										</Grid>
										<Grid item md={9} xs={9}>
											<div style={{ marginLeft: 5 }}>New Delay Code</div>
										</Grid>
									</Grid>
								</MenuItem>
								{delayNodeSelectedInTree?.IsBranch == false && (
									<MenuItem
										className={classes.menuItem}
										onClick={() => setShowDeleteModal(true)}
									>
										<Grid container style={{ alignItems: "center" }}>
											<Grid item md={2} xs={3}>
												<DeleteIcon />
											</Grid>
											<Grid item md={9} xs={9}>
												<div style={{ marginLeft: 5 }}>Delete Delay Code</div>
											</Grid>
										</Grid>
									</MenuItem>
								)}
							</ul>
						) : (
							""
						)}
						{showCreateNewContextMenu ? (
							<ul className={classes.contextMenu} style={{ top: y, left: x }}>
								<MenuItem
									className={classes.menuItem}
									onClick={() => setShowCreateModal(true)}
								>
									<Grid container style={{ alignItems: "center" }}>
										<Grid item md={2} xs={3}>
											<PlaylistAddIcon style={{ width: 18 }} />
										</Grid>
										<Grid item md={9} xs={9}>
											<div style={{ marginLeft: 5 }}>New Delay Code</div>
										</Grid>
									</Grid>
								</MenuItem>
							</ul>
						) : (
							""
						)}
					</div>
				</div>
				<ErrorModal error={errorUpdate} onHide={() => setErrorUpdate("")} />
				<ErrorModal error={error} onHide={() => setError("")} />
				<ErrorModal error={searchError} onHide={() => setSearchError("")} />
			</div>
		);

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<LazyLoading />
		</div>
	);
};
