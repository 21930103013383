import { get } from "react-hook-form";
import { useLogbookContext } from "../context/logbookContext";
import { getEntry } from "../repository/LogbookRepository";

type Props = {
	setIsEntryLoading: (isLoading: boolean) => void;
	setEntryError: (err: string) => void;
	EntryId: number | string;
};

export const useSearchEntry = ({
	setEntryError,
	setIsEntryLoading,
	EntryId,
}: Props) => {
	const {
		actions: { setEntrySelectedInTable },
	} = useLogbookContext();
	return async () => {
		setIsEntryLoading(true);
		setEntrySelectedInTable([]);
		const res = await getEntry(parseInt(EntryId as string));
		if (res.ok) {
			const rowsSource = get(res, "data", []);
			const dataTable = rowsSource.tables[0];
			setEntrySelectedInTable(dataTable.rows[0]);
		} else setEntryError(res.message);
		setIsEntryLoading(false);
	};
};
