import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { DelayCodeAPI } from "../../models/DelayCodeAPI";

export const insertDelay = async (
	AssetId: number,
	Timestamp: Date,
	IsDelayManual: boolean | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "AssetId", value: 11 });
	parameters.push({ name: "Timestamp", value: Timestamp.toISOString() });
	parameters.push({ name: "IsDelayManual", value: IsDelayManual });

	return await apiService.callV2("[DEL].[CreateDelay]", parameters);
};

export const updateDelayData = async (DelayCode: DelayCodeAPI) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({
		name: "DelayAreaAssetId",
		value: DelayCode.DelayAreaAssetId,
	});
	parameters.push({ name: "DelayCodeId", value: DelayCode.DelayCodeId });
	parameters.push({ name: "DelayCodeName", value: DelayCode.DelayCodeName });
	parameters.push({
		name: "ParentDelayCodeId",
		value:
			DelayCode.ParentDelayCodeId === 0 ? null : DelayCode.ParentDelayCodeId,
	});
	parameters.push({
		name: "DefaultDelayCategoryId",
		value: DelayCode.DelayCategoryId,
	});
	parameters.push({ name: "IsActive", value: DelayCode.IsActive });

	return await apiService.callV2("[DEL].[UpsertDelayCode]", parameters);
};

export const removeDelay = async (DelayCodeId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "DelayCodeId", value: DelayCodeId });

	return await apiService.callV2("[DEL].[DeleteDelayCode]", parameters);
};
export const getDelays = async (assetId?: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	if (assetId) parameters.push({ name: "DelayAreaAssetId", value: assetId });
	return await apiService.callV2("[DEL].[GetDelayCodes]", parameters);
};
export const getDelay = async (DelayCodeId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "DelayCodeId", value: DelayCodeId });
	return await apiService.callV2("[DEL].[GetDelayCode]", parameters);
};

export const reorderDelays = async (
	DelayCodeId: (string | number)[],
	AboveDelayCodeId: number | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "DelayCodeId", value: DelayCodeId.join(",") });
	parameters.push({ name: "AboveDelayCodeId", value: AboveDelayCodeId });

	return await apiService.callV2("[Del].[ReorderDelayCode]", parameters);
};
