import { Route, Routes } from "react-router-dom";
import { EntryViewer } from "./components/entry-viewer/entry-viewer";
import { LogbookProvider } from "./context/logbookContext";
import EntriesBrowser from "./entries-browser";

const LogbookIndex = () => {
	return (
		<LogbookProvider>
			<Routes>
				<Route path={""} element={<EntriesBrowser />}>
					<Route path=":EntryId" element={<EntryViewer />}></Route>
				</Route>
			</Routes>
		</LogbookProvider>
	);
};

export default LogbookIndex;
