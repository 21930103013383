import { ButtonWithLoading, ErrorModal } from "@dexteel/mesf-core";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
	DeliveryTicket,
	ProductBatch,
} from "../../../../../models/DeliveryTicket";
import { upsertProductBatch } from "../../../../../repositories/ProductionRepository";
import { useCastersContext } from "../../../../CastersContext";
import { calculateWeightPerPiece } from "../../../utils/calculateWeightPerPiece";
import { LengthInput } from "./components/LengthInput";
import { PiecesInput } from "./components/PiecesInput";
import { SectionInput } from "./components/SectionInput";
import { WeightInput } from "./components/WeightInput";
import { WeightPerPieceInput } from "./components/WieghtPerPieceInput";

import { CatalogMoldSize } from "@/pages/caster/models/catalogs/CatalogMoldSize";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useDeliveryTicketStyles } from "../styles/deliveryTicketStyles";
interface StrandFieldsProps {
	deliveryTicket: DeliveryTicket | null;
	moldSizes: CatalogMoldSize[] | null;
	handleProductBatchChange: (index: number, key: string, value: string) => void;
	errors: Map<string, string>;
	setErrors: Dispatch<SetStateAction<Map<string, string>>>;
	refreshProductBatches: () => void;
	isLoading: boolean;
}

export const ProductBatchSection = ({
	errors,
	setErrors,
	deliveryTicket,
	moldSizes,
	handleProductBatchChange,
	refreshProductBatches,
	isLoading,
}: StrandFieldsProps) => {
	const classes = useDeliveryTicketStyles();
	const titleOfColumns = ["Length", "Pieces", "Section", "Weight", "Weight/Pc"];
	const [error, setError] = useState("");
	const { state, actions } = useCastersContext();

	const handleAddField = (
		e: React.MouseEvent | null,
		initialWeight: string | null = null,
	) => {
		if (e) e.preventDefault();
		if (deliveryTicket && deliveryTicket.productBatches.length < 8) {
			const newPBId = -Math.floor(Math.random() * 1000000) - 1;

			const newBatch: any = {
				ProductBatchId: newPBId, // Random negative integer
				Length: "0",
				Pieces: 1,
				SectionName: "",
				SectionId: 0,
				Weight: initialWeight || 0,
				WeightPerPiece: "",
			};
			const updatedBatches = [...deliveryTicket.productBatches, newBatch];
			handleProductBatchChange(
				-1,
				"updateAllBatches",
				JSON.stringify(updatedBatches),
			);
		}
	};

	const handleDeleteBatch = async (batch: ProductBatch) => {
		if (deliveryTicket && state.processId) {
			const batchToDelete = deliveryTicket.productBatches.find(
				(pb) => pb.ProductBatchId === batch.ProductBatchId,
			);
			// PBatch exists in db?
			if (batchToDelete?.ProductBatchId && batchToDelete?.ProductBatchId > 0) {
				const resp = await upsertProductBatch(
					batchToDelete?.ProductBatchId,
					state.processId,
					batchToDelete?.Length,
					batchToDelete?.SectionId,
					0,
					batchToDelete?.Weight,
				);

				if (!resp.ok) {
					setError(resp.message);
					return;
				}
			}
			// Front-ent list
			const updatedBatches = deliveryTicket.productBatches.filter(
				(pb) => pb.ProductBatchId !== batch.ProductBatchId,
			);
			handleProductBatchChange(
				-1,
				"updateAllBatches",
				JSON.stringify(updatedBatches),
			);
			setErrors((prevErrors) => {
				const newErrors = new Map(prevErrors);
				for (const key of newErrors.keys()) {
					if (key.endsWith(`-${batch.ProductBatchId}`)) {
						newErrors.delete(key);
					}
				}
				return newErrors;
			});
		}
	};
	const handleLengthChange = (
		batchIndex: number,
		feet: number,
		inches: number,
		ProductBatchId: number,
	) => {
		const totalInches = feet * 12 + inches;
		handleProductBatchChange(batchIndex, "Length", totalInches.toString());

		// Clear any existing errors for this field
		setErrors((prev) => {
			const newErrors = new Map(prev);
			newErrors.delete(`Length-${ProductBatchId}`);
			return newErrors;
		});
	};
	const handleSectionChange = (
		value: string,
		batchIndex: number,
		ProductBatchId: number,
	) => {
		handleProductBatchChange(batchIndex, "SectionId", value);
		// Clear any existing errors for this field
		setErrors((prev) => {
			const newErrors = new Map(prev);
			newErrors.delete(`Section-${ProductBatchId}`);
			return newErrors;
		});
	};
	const getWeightFromMoldSize = () => {
		return (
			moldSizes
				?.find((ms) => ms.MoldSizeId === deliveryTicket?.heatData.MoldSizeId)
				?.LbsPerFoot.toString() || ""
		);
	};

	return (
		<>
			<Grid container spacing={1}>
				<Grid container item xs={12} spacing={2} alignItems="center">
					<Grid item xs={1}></Grid>
					{titleOfColumns.map((field, index) => (
						<Grid item xs={2} key={field}>
							<Typography
								align="center"
								style={{
									fontWeight: "bold",
									marginRight:
										index === titleOfColumns.length - 1 ? "24px" : "0",
								}}
							>
								{field}
							</Typography>
						</Grid>
					))}
					<Grid item xs={1}>
						<ButtonWithLoading
							isLoading={isLoading}
							fullWidth
							variant="outlined"
							color="primary"
							onClick={() => {
								refreshProductBatches();
							}}
							title="Get from strands"
						>
							<GetAppIcon />
						</ButtonWithLoading>
					</Grid>
				</Grid>

				{deliveryTicket?.productBatches.map((batch, batchIndex) => (
					<Grid
						container
						item
						xs={12}
						spacing={2}
						alignItems="center"
						key={batchIndex}
					>
						<Grid item xs={1}></Grid>
						<Grid item xs={2}>
							<LengthInput
								feet={Math.floor(Number(batch.Length) / 12) || 0}
								inches={Number(batch.Length) % 12 || 0}
								onChange={(feet, inches) =>
									handleLengthChange(
										batchIndex,
										feet,
										inches,
										batch.ProductBatchId,
									)
								}
								error={errors.has(`Length-${batch.ProductBatchId}`)}
								helperText={errors.get(`Length-${batch.ProductBatchId}`)}
								className={classes.input}
							/>
						</Grid>
						<Grid item xs={2}>
							<PiecesInput
								value={batch.Pieces}
								onChange={(value) =>
									handleProductBatchChange(batchIndex, "Pieces", value)
								}
								error={errors.has(`Pieces-${batch.ProductBatchId}`)}
								helperText={errors.get(`Pieces-${batch.ProductBatchId}`)}
								className={classes.input}
							/>
						</Grid>
						<Grid item xs={2}>
							<SectionInput
								value={batch.SectionId}
								onChange={(value) =>
									handleSectionChange(value, batchIndex, batch.ProductBatchId)
								}
								className={`${classes.textFieldMUI} ${classes.input}`}
								error={errors.has(`Section-${batch.ProductBatchId}`)}
								helperText={errors.get(`Section-${batch.ProductBatchId}`)}
							/>
						</Grid>
						<Grid item xs={2}>
							<WeightInput
								value={batch.Weight}
								onChange={(value) =>
									handleProductBatchChange(batchIndex, "Weight", value)
								}
								error={errors.has(`Weight-${batch.ProductBatchId}`)}
								helperText={errors.get(`Weight-${batch.ProductBatchId}`)}
								className={classes.input}
							/>
						</Grid>
						<Grid item xs={2}>
							<WeightPerPieceInput
								value={
									batch.WeightPerPiece ||
									(batch.Weight && batch.Pieces
										? calculateWeightPerPiece(batch.Weight, batch.Pieces)
										: "")
								}
								error={errors.has(`WeightPerPiece-${batch.ProductBatchId}`)}
								helperText={errors.get(
									`WeightPerPiece-${batch.ProductBatchId}`,
								)}
								className={classes.disabledInput + " " + classes.input}
							/>
						</Grid>
						<Grid item xs={1} container justifyContent="center">
							{batchIndex > 0 && (
								<IconButton
									onClick={() => handleDeleteBatch(batch)}
									size="small"
									color="secondary"
									title="Delete"
								>
									<DeleteIcon style={{ cursor: "pointer" }} />
								</IconButton>
							)}
						</Grid>
					</Grid>
				))}

				<Grid item xs={1}></Grid>
				{deliveryTicket && deliveryTicket.productBatches.length < 8 && (
					<Grid item xs={2}>
						<Button
							variant="contained"
							color="primary"
							className={`${classes.addButton}`}
							onClick={(e) => handleAddField(e)}
							title="Add new line"
						>
							+
						</Button>
					</Grid>
				)}
				<div style={{ height: "180px" }}></div>
			</Grid>

			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
