import { AssetsFilter } from "@/controls/assetsFilter/AssetsFilter";
import {
	ShiftDayNavigatorControl,
	useShiftNavigator,
	useUserContext,
} from "@dexteel/mesf-core";
import { Button, Grid, makeStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogbookContext } from "../../context/logbookContext";
import { FILTER_INITIAL_VALUES } from "../../models/FilterParametersInitialValue";
import { SearchFilter } from "./searchFilter";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1),
		height: "auto",
		margin: 0,
	},
	btnExcel: {
		backgroundColor: "#217346",
		color: "white",
		"&:hover": {
			backgroundColor: "#1e6e3e",
		},
	},
	input: {
		"& .MuiInputBase-root": {
			height: "36px",
		},
	},
}));

type Props = {
	searchEntries: Function;
};

export const Filters = ({ searchEntries }: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { initShiftNavigator, shiftInfo } = useShiftNavigator();
	const {
		state: {
			FilterParameters: { AssetId },
			exportExcel,
		},
		actions: { setExportExcel, setFilter, setFilterAssetId },
	} = useLogbookContext();

	const handleSearch = () => {
		searchEntries();
	};

	const onKeyDown = (e: any) => {
		if (e.keyCode === 13) {
			searchEntries();
		}
	};

	const handleReset = async () => {
		setFilter(FILTER_INITIAL_VALUES);
		initShiftNavigator();
		navigate("/logbook");
	};

	useEffect(() => {
		searchEntries();
	}, [shiftInfo, AssetId]);

	useEffect(() => {
		(async () => {
			await initShiftNavigator();
		})();
	}, []);

	return (
		<Grid container spacing={2} className={classes.container}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid
						item
						xs={12}
						md={6}
						container
						spacing={2}
						justifyContent="space-between"
					>
						<div style={{ paddingLeft: "16px", minWidth: "450px" }}>
							<ShiftDayNavigatorControl preventInit />
						</div>
						<Grid
							item
							xs={12}
							md={3}
							alignContent="center"
							justifyContent="center"
						>
							<AssetsFilter
								title="Asset"
								value={AssetId}
								onChange={(newValue) => {
									setFilterAssetId(newValue);
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						container
						spacing={2}
						alignItems="center"
						style={{ paddingLeft: "37px" }}
					>
						<Grid item xs={12} md={8} container spacing={1}>
							<Grid item xs={4}>
								<Button
									fullWidth
									variant="contained"
									color="default"
									startIcon={<RefreshIcon />}
									onClick={handleReset}
								>
									Reset
								</Button>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4}>
							<Button
								fullWidth
								variant="contained"
								startIcon={<GetAppIcon />}
								onClick={() => setExportExcel(!exportExcel)}
								className={classes.btnExcel}
							>
								Export Excel
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={1} style={{ padding: "0px 55px 0px 16px" }}>
						<Grid
							item
							xs={12}
							md={5}
							alignContent="center"
							justifyContent="center"
						>
							<SearchFilter onKeyDown={onKeyDown} />
						</Grid>
						<Grid item xs={1} alignContent="center" justifyContent="center">
							<Button
								fullWidth
								variant="contained"
								color="primary"
								startIcon={<SearchIcon />}
								onClick={handleSearch}
								style={{ height: "70%" }}
							>
								Search
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
