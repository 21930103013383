import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef } from "ag-grid-enterprise";
import moment from "moment";
import { CategoryTag } from "../../delays-manager/components/CategoryTag";

const applyCommonColumnSettings = (column: ColDef): ColDef => {
	return {
		...column,
		sortable: true,
		wrapText: true,
		autoHeight: true,
		wrapHeaderText: true,
	};
};

export const useTableData = () => {
	const columns: ColDef[] = [
		applyCommonColumnSettings({
			field: "Start",
			headerName: "Date",
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.Start}`, "MM/dd") || "",
			minWidth: 60,
			maxWidth: 60,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Shift",
			headerName: "Shift",
			valueGetter: (params) => params.data?.Shift || "",
			minWidth: 60,
			maxWidth: 60,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Crew",
			headerName: "Crew",
			valueGetter: (params) => params.data?.Crew || "",
			minWidth: 60,
			maxWidth: 60,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Start",
			headerName: "Start",
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.Start}`, "HH:mm:ss") || "",
			minWidth: 80,
			maxWidth: 80,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "End",
			headerName: "End",
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.End}`, "HH:mm:ss") || "",
			minWidth: 80,
			maxWidth: 80,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Duration",
			headerName: "Duration",
			valueFormatter: (params) => {
				if (params.value) {
					const duration = moment.duration(params.value, "seconds");
					const minutes = Math.floor(duration.asMinutes());
					const seconds = duration.seconds();
					return `${minutes}m ${seconds}s`;
				}
				return "";
			},
			minWidth: 80,
			maxWidth: 80,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "AssetName",
			headerName: "Asset",
			maxWidth: 80,
			minWidth: 80,
			editable: false,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "DelayCategory",
			headerName: "Category",
			cellRendererFramework: CategoryTag,
			maxWidth: 150,
			minWidth: 150,
			editable: false,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "DelayCodeName",
			headerName: "Code",
			valueGetter: (params) => params.data?.DelayCodeName || "No Classified",
			maxWidth: 150,
			minWidth: 150,
			editable: false,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Comments",
			headerName: "Comments",
			minWidth: 120,
			flex: 1,
			cellStyle: { justifyContent: "flex-start" },
		}),
		applyCommonColumnSettings({
			field: "JustifiedByUser",
			headerName: "User",
			maxWidth: 100,
			minWidth: 100,
			editable: false,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "JustifiedDateTime",
			headerName: "Justification",
			valueGetter: (params) =>
				dxtToLocalServerTime(params.data?.JustifiedDatetime, "MM-dd HH:mm") ||
				"",
			maxWidth: 150,
			minWidth: 100,
			flex: 1,
		}),
	];

	return { columns };
};
