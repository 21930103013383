import {
	ButtonWithLoading,
	ErrorModal,
	MesfModal,
	getMomentTz,
	useUTLSettingsContext,
} from "@dexteel/mesf-core";
import { Button, Grid, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { get } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useModalStyles } from "../../../../eaf-processes/styles/modalStyles";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { Charge } from "../../../models/eaf.models";
import { DateTimePickerDE } from "../../form-fields/common/DateTimePickerDE";
import { NumberTextFieldDE } from "../../form-fields/common/NumberTextFieldDE";
import { validateField } from "../../tables/utils/validateMinMax";
import { BucketsSelector } from "./components/BucketsSelector";
type Props = {
	show: boolean;
	onHide: () => void;
	date: Date;
	limitDates?: {
		start: Date;
		end: Date | null;
	};
};
const moment = getMomentTz();
export const CreateCharge = ({ show, onHide, date, limitDates }: Props) => {
	const { state } = useUTLSettingsContext();

	const MIN_WEIGHT = get(state.settings, "MAX_WEIGHT_CHARGE", 0);
	const MAX_WEIGHT = get(state.settings, "MIN_WEIGHT_CHARGE", 200000);

	const { saveCharge } = useEAFDataEntryActions();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const classes = useModalStyles();
	const {
		getValues,
		handleSubmit,
		setValue,
		control,
		reset,
		formState: { errors },
	} = useForm<Charge>({});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const onSubmit: SubmitHandler<any> = async (data: any) => {
		setIsSubmitLoading(true);
		const resp = await saveCharge(data);
		setIsSubmitLoading(false);
		onHide();
	};

	useEffect(() => {
		if (show) {
			reset();
			const newDate = moment(date).add(1, "minutes").toDate();
			newDate.setSeconds(0);

			setValue("Timestamp", newDate);
		}
	}, [show]);
	return (
		<>
			<Grid container>
				<Grid item>
					<MesfModal
						title="NEW MANUAL CHARGE"
						open={show}
						handleClose={() => onHide()}
						id="create-charge-modal"
						maxWidth="sm"
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content dividers style={{ padding: "15px 30px" }}>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<Controller
											name="BucketId"
											control={control}
											render={({ field }) => (
												<BucketsSelector
													value={field.value || null}
													onChange={field.onChange}
												/>
											)}
										/>
										{errors.BucketId && (
											<span className={classes.errorLabel}>
												{errors.BucketId.message}
											</span>
										)}
									</Grid>
									<Grid item xs={12} md={6}>
										<Controller
											name="Weight"
											control={control}
											rules={{
												required: "Weight is required",
												validate: (value) =>
													validateField(
														value,
														MIN_WEIGHT,
														MAX_WEIGHT,
														"Weight",
													),
											}}
											render={({ field }) => (
												<NumberTextFieldDE
													{...field}
													value={getValues("Weight")}
													label="Scrap Weight [lbs]"
													onChange={(val) => setValue("Weight", val)}
													onBlur={() => {}}
												/>
											)}
										/>
										{errors.Weight && (
											<span className={classes.errorLabel}>
												{errors.Weight.message}
											</span>
										)}
									</Grid>
									<Grid item xs={12} md={6}>
										<Controller
											name="Timestamp"
											control={control}
											rules={{
												required: "Timestamp is required",
												validate: (value) => {
													if (limitDates?.start && value < limitDates.start) {
														return "Timestamp must be between the start and end of the Process";
													} else if (
														limitDates?.end &&
														value > limitDates.end
													) {
														return "Timestamp must be less than the end of the process";
													} else {
														return true;
													}
												},
											}}
											render={({ field }) => (
												<DateTimePickerDE
													{...field}
													format={"HH:mm - MM/DD/YYYY"}
													value={getValues("Timestamp")}
													label="Time"
													error={errors.Timestamp?.message}
													onChange={(val) => setValue("Timestamp", val)}
													onBlur={() => {}}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</MesfModal.Content>
							<MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide()}
											size="large"
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<ButtonWithLoading
											fullWidth
											isLoading={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
											size="large"
										>
											Save
										</ButtonWithLoading>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
					<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
						<MuiAlert
							elevation={6}
							variant="filled"
							severity="success"
							onClose={handleClose}
						>
							The charge was created successfully
						</MuiAlert>
					</Snackbar>
				</Grid>
			</Grid>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Creating Manual Probe"
			/>
		</>
	);
};
