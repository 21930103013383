import {
	ButtonWithLoading,
	ErrorModal,
	MesfModal,
	getMomentTz,
	useUTLSettingsContext,
} from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import { get } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CatalogMaterial } from "../../../../../steel/models/CatalogMaterial";
import { useModalStyles } from "../../../../eaf-processes/styles/modalStyles";
import { useDataEntryContext } from "../../../context/reducer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { Addition } from "../../../models/eaf.models";
import { adjustTimestampDate } from "../../../utils/adjustTimestampDate";
import { isTimestampBetweenStartAndEnd } from "../../../utils/isTimesTampBetweenStartAndEnd";
import { DateTimePickerDE } from "../../form-fields/common/DateTimePickerDE";
import { NumberTextFieldDE } from "../../form-fields/common/NumberTextFieldDE";
import { validateField } from "../../tables/utils/validateMinMax";
type Props = {
	show: boolean;
	onHide: () => void;
	addition: Addition;
};
const moment = getMomentTz();
export const EditAddition = ({ show, onHide, addition }: Props) => {
	const { state } = useUTLSettingsContext();

	const MIN_WEIGHT = get(state.settings, "MAX_WEIGHT_ADDITION", 0);
	const MAX_WEIGHT = get(state.settings, "MIN_WEIGHT_ADDITION", 5000);

	const {
		state: { deProcess },
	} = useDataEntryContext();
	const { saveAddition, getMaterials } = useEAFDataEntryActions();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const [materials, setMaterials] = useState<CatalogMaterial[]>([]);
	const classes = useModalStyles();
	const {
		getValues,
		handleSubmit,
		setValue,
		control,
		reset,
		formState: { errors },
	} = useForm<Addition>({});
	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};
	const handleChangeTime = (val: Date | null) => {
		if (!val) return;

		const timestamp = moment.utc(val);
		const start = moment.utc(deProcess?.process.Start);
		const end = deProcess?.process.End
			? moment.utc(deProcess.process.End)
			: null;

		const adjustedTimestamp = adjustTimestampDate(timestamp, start, end);

		setValue("Timestamp", adjustedTimestamp.toDate());
	};

	const onSubmit: SubmitHandler<any> = async (data: any) => {
		setIsSubmitLoading(true);
		const resp = await saveAddition({
			...data,
			AdditionId: addition.AdditionId,
		});
		setIsSubmitLoading(false);
		onHide();
	};
	useEffect(() => {
		if (show) {
			reset();
			(async () => {
				const materials: CatalogMaterial[] = await getMaterials();
				setMaterials(
					materials.filter((material) => material.UseInEAF === true),
				);
			})();
			setValue("Timestamp", addition?.Timestamp);
			setValue("MaterialId", addition?.MaterialId);
			setValue("Weight", addition?.Weight);
		}
	}, [show]);
	return (
		<>
			<Grid container>
				<Grid item>
					<MesfModal
						title="EDIT MANUAL ADDITION"
						open={show}
						handleClose={() => onHide()}
						id="edit-addition-modal"
						maxWidth="sm"
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content dividers style={{ padding: "15px 30px" }}>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<Controller
											name="Weight"
											control={control}
											rules={{
												required: "Weight is required",
												validate: (val) =>
													validateField(val, MIN_WEIGHT, MAX_WEIGHT, "Weight"),
											}}
											render={({ field }) => (
												<NumberTextFieldDE
													{...field}
													value={getValues("Weight")}
													label="Weight [kg]"
													onChange={(val) => setValue("Weight", val)}
													onBlur={() => {}}
												/>
											)}
										/>
										{errors.Weight && (
											<span className={classes.errorLabel}>
												{errors.Weight.message}
											</span>
										)}
									</Grid>

									<Grid item xs={12} md={6}>
										<Controller
											name="MaterialId"
											control={control}
											rules={{ required: "Material is required" }}
											render={({ field }) => (
												<Autocomplete
													id="clear-on-escape"
													clearOnEscape
													options={materials ?? []}
													disabled={!addition.IsAdditionManual}
													getOptionLabel={(option) => option.MaterialName}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Material"
															variant="outlined"
															fullWidth
															size="small"
															InputProps={{
																...params.InputProps,
																className: classes.autocompleteField,
															}}
														/>
													)}
													value={
														materials.find(
															(material) => material.MaterialId === field.value,
														) || null
													}
													onChange={(event, newValue) => {
														field.onChange(
															newValue ? newValue.MaterialId : null,
														);
													}}
												/>
											)}
										/>
										{errors.MaterialId && (
											<span className={classes.errorLabel}>
												{errors.MaterialId.message}
											</span>
										)}
									</Grid>
									<Grid item xs={12} md={6}>
										<Controller
											name="Timestamp"
											control={control}
											rules={{
												required: "Timestamp is required",
												validate: (value) => {
													const start = moment
														.utc(deProcess?.process.Start)
														.toDate();
													const end = deProcess?.process.End
														? moment.utc(deProcess?.process.End).toDate()
														: null;
													//Compare datetimes
													if (
														!isTimestampBetweenStartAndEnd(
															moment.utc(value).toDate(),
															start,
															end,
														)
													) {
														if (!end)
															return "Timestamp must be greater than the start date";
														else
															return "Timestamp must be between Start and End date time";
													}
													return true;
												},
											}}
											render={({ field }) => (
												<DateTimePickerDE
													{...field}
													format={"HH:mm - MM/DD/YYYY"}
													disabled={!addition.IsAdditionManual}
													value={getValues("Timestamp")}
													label="Time"
													onChange={handleChangeTime}
													onBlur={() => {}}
													error={errors.Timestamp?.message}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</MesfModal.Content>
							<MesfModal.Actions style={{ padding: "20px" }}>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide()}
											size="large"
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12}>
										<ButtonWithLoading
											fullWidth
											isLoading={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
											size="large"
										>
											Save
										</ButtonWithLoading>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
					<Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
						<MuiAlert
							elevation={6}
							variant="filled"
							severity="success"
							onClose={handleClose}
						>
							The addition was edited successfully
						</MuiAlert>
					</Snackbar>
				</Grid>
			</Grid>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Creating Manual Probe"
			/>
		</>
	);
};
