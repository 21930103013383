import { useUserContext } from "@dexteel/mesf-core";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

const StyledFormControl = styled(FormControl)({
	width: "80%",
	"& .MuiInputBase-root": {
		height: "40px",
		borderRadius: "4px",
		border: "1px solid rgba(0, 0, 0, 0.23)",
		"&:hover": {
			borderColor: "rgba(0, 0, 0, 0.87)",
		},
		"&:before, &:after": {
			display: "none",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
		},
	},
	"& .MuiInputLabel-root": {
		transform: "translate(14px, 8px) scale(1)",
		"&.MuiInputLabel-shrink": {
			transform: "translate(14px, -6px) scale(0.75)",
			backgroundColor: "white",
			padding: "0 4px",
		},
	},
	"& .MuiSelect-select": {
		paddingTop: "0",
		paddingBottom: "0",
		height: "100%",
		display: "flex",
		alignItems: "center",
	},
});

type Props = {
	delayAreas: Array<{ AssetId: number; AssetName: string }>;
	selectedDelayArea: number | null;
	onChange: (assetId: number | null) => void;
};

export const DelayAreaFilter: React.FC<Props> = ({
	delayAreas,
	selectedDelayArea,
	onChange,
}) => {
	const {
		state: { defaultAreaId },
	} = useUserContext();

	useEffect(() => {
		if (defaultAreaId && defaultAreaId !== 10) {
			onChange(defaultAreaId as number);
		} else if (defaultAreaId === 10) {
			onChange(null);
		}
	}, [defaultAreaId]);

	return (
		<StyledFormControl variant="outlined">
			<InputLabel id="delay-area-label">Delay Area</InputLabel>
			<Select
				labelId="delay-area-label"
				value={selectedDelayArea === null ? "all" : selectedDelayArea}
				label="Delay Area"
				onChange={(e) => {
					const value = e.target.value;
					onChange(value === "all" ? null : (value as number));
				}}
			>
				<MenuItem value="all">All</MenuItem>
				{delayAreas.map((area) => (
					<MenuItem key={area.AssetId} value={area.AssetId}>
						{area.AssetName}
					</MenuItem>
				))}
			</Select>
		</StyledFormControl>
	);
};
