import { DelayCode } from "@/pages/delays/models/Delay";
import { TreeCode } from "@/pages/delays/models/TreeCode";
import { useState } from "react";

type Props = {
	allDelayCodes: DelayCode[];
};

export const useTreeBuilder = ({ allDelayCodes }: Props) => {
	const [delayCodes, setDelayCodes] = useState<TreeCode | null>(null);

	const buildTreeDelays = (nodes: TreeCode[]) => {
		if (nodes === undefined) return;

		// Create an id-to-index mapping for efficient lookup
		const idMapping: Record<number, number> = nodes.reduce(
			(acc, el, i) => {
				acc[el.id] = i;
				return acc;
			},
			{} as Record<number, number>,
		);

		let root: TreeCode | undefined;
		nodes.forEach((el) => {
			// Identify and set the root element
			if (el.parentId === null) {
				root = el;
				return;
			}
			// Use the mapping to locate the parent element in nodes
			const parentEl = nodes[idMapping[el.parentId]];
			// Handle cases where parent is not found
			if (!parentEl) {
				return;
			}
			// Initialize children array if necessary and add current element
			if (!parentEl.children) {
				parentEl.children = [];
			}
			parentEl.children.push(el);
		});
		setDelayCodes(root || null); // Set root to delayCodes state, handling null if undefined
	};

	const loadFilterDelays = () => {
		const nodes = allDelayCodes.map(
			(del: DelayCode) =>
				({
					id: del.DelayCodeId,
					parentId: del.ParentDelayCodeId,
					name: del.DelayCodeName,
					isLeaf: !!del.IsLeaf,
					isActive: true,
					children: [],
				}) as TreeCode,
		);
		buildTreeDelays(nodes);
	};

	return { loadFilterDelays, delayCodes };
};
