import { ErrorModal } from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	Snackbar,
	makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { SyntheticEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLogbookContext } from "../../context/logbookContext";
import { deleteEntry } from "../../repository/LogbookRepository";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 120,
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
	infoLabel: {
		fontSize: 12,
		color: "#757575",
	},
}));

type Props = {
	show: boolean;
	onHide: (onHide: boolean) => void;
	// searchData: Function;
};

export const RemoveEntryDialog = ({ show, onHide }: Props) => {
	const classes = useStyles();
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [documentRemovedSuccess, setEntryRemovedSuccess] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const params = useParams();
	const { EntryId } = useParams();
	const navigate = useNavigate();

	const [error, setError] = useState<string>("");
	const {
		state: { EntriesList, EntryIdToDelete, EntryTitleToDelete },
		actions: { setEntrySelectedInTable },
	} = useLogbookContext();

	const handleCancel = () => {
		setIsSubmitLoading(false);
		onHide(false);
	};

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setEntryRemovedSuccess(false);
	};

	const handleRemoveEntry = async () => {
		setIsSubmitLoading(true);
		const res = await deleteEntry(EntryIdToDelete);
		if (res.ok) {
			if (parseInt(EntryId as string) === EntryIdToDelete) {
				setEntrySelectedInTable([]);
				navigate(`/logbook`, { replace: true });
			}
			setEntryRemovedSuccess(true);
			setIsSubmitLoading(false);
			onHide(true);
		} else {
			setError(res.message);
			setIsSubmitLoading(false);
		}
	};

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={() => handleCancel()}
				disableEnforceFocus
				className={classes.root}
			>
				<DialogTitle id="responsive-dialog-title" style={{ paddingBottom: 0 }}>
					{"WARNING"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText style={{ padding: 0, margin: 0 }}>
						Be careful, you are about to remove: <b>{EntryTitleToDelete}</b>.
						Once deleted you will not be able to recover this Entry. Are you
						sure you want to delete it?
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ padding: "0 24px 16px" }}>
					<Button onClick={() => onHide(false)} autoFocus color="default">
						CANCEL
					</Button>
					<Button
						autoFocus
						color="secondary"
						startIcon={isSubmitLoading && <CircularProgress size="1rem" />}
						onClick={handleRemoveEntry}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={documentRemovedSuccess}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<Alert severity="info" onClose={handleClose}>
					Entry removed succesfully
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
