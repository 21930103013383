import { useUserContext } from "@dexteel/mesf-core";
import { ReactNode, createContext, useContext, useEffect } from "react";
import { useComplexState } from "use-complex-state";
import { LogbookReducer } from "../reducers/logbookReducer";

const LogbookContext = createContext({
	state: LogbookReducer.getInitialState(),
	actions: LogbookReducer.actions,
});

export const useLogbookContext = () => useContext(LogbookContext);

export const LogbookProvider = ({ children }: { children: ReactNode }) => {
	const {
		state: { defaultAreaId },
	} = useUserContext();

	const [state, actions] = useComplexState({
		initialState: LogbookReducer.getInitialState(),
		reducers: LogbookReducer.caseReducers,
	});

	useEffect(() => {
		if (
			!state.FilterParameters.AssetId &&
			defaultAreaId &&
			defaultAreaId != 10
		) {
			actions.setFilterAssetId(defaultAreaId);
		}
	}, []);
	return (
		<LogbookContext.Provider value={{ state, actions }}>
			{children}
		</LogbookContext.Provider>
	);
};
