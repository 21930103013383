import {
	Checkbox,
	FormControlLabel,
	Grid,
	makeStyles,
} from "@material-ui/core";
import { Moment } from "moment";
import React from "react";
import { DelayAreaFilter } from "./DelayAreaFilter";
import { EndFilter } from "./EndFilter";
import { StartFilter } from "./StartFilter";

const useStyles = makeStyles((theme) => ({
	filtersContainer: {
		gap: theme.spacing(2),
		alignItems: "flex-end",
		marginBottom: theme.spacing(2),
	},
	filterItem: {
		maxWidth: "250px",
		minWidth: "150px",
	},
}));

type Props = {
	onFilterChange: (startDate: Moment, endDate: Moment) => void;
	initialStartDate: Moment;
	initialEndDate: Moment;
	includeMicroDelays: boolean;
	includeUndeclared: boolean;
	onIncludeMicroDelaysChange: (includeMicrodelays: boolean) => void;
	onIncludeUndeclaredChange: (includeUndeclared: boolean) => void;
	delayAreas: Array<{ AssetId: number; AssetName: string }>;
	selectedDelayArea: number | null;
	onDelayAreaChange: (assetId: number | null) => void;
};

export const Filters: React.FC<Props> = ({
	onFilterChange,
	initialStartDate,
	initialEndDate,
	includeMicroDelays,
	includeUndeclared,
	onIncludeMicroDelaysChange,
	onIncludeUndeclaredChange,
	delayAreas,
	selectedDelayArea,
	onDelayAreaChange,
}) => {
	const classes = useStyles();

	const handleStartDateChange = (date: Moment | null) => {
		if (date) {
			onFilterChange(date, initialEndDate);
		}
	};

	const handleEndDateChange = (date: Moment | null) => {
		if (date) {
			onFilterChange(initialStartDate, date);
		}
	};

	return (
		<Grid
			container
			className={classes.filtersContainer}
			spacing={2}
			alignItems="flex-end"
			wrap="nowrap"
		>
			<Grid item xs="auto" className={classes.filterItem}>
				<StartFilter
					value={initialStartDate}
					onChange={handleStartDateChange}
				/>
			</Grid>
			<Grid item xs="auto" className={classes.filterItem}>
				<EndFilter value={initialEndDate} onChange={handleEndDateChange} />
			</Grid>
			<Grid item xs="auto" className={classes.filterItem}>
				<DelayAreaFilter
					delayAreas={delayAreas}
					selectedDelayArea={selectedDelayArea}
					onChange={onDelayAreaChange}
				/>
			</Grid>
			<Grid item xs="auto">
				<FormControlLabel
					control={
						<Checkbox
							color="primary"
							checked={includeMicroDelays}
							onChange={(e) => onIncludeMicroDelaysChange(e.target.checked)}
						/>
					}
					label="Include Microdelays"
				/>
			</Grid>
			<Grid item xs="auto">
				<FormControlLabel
					control={
						<Checkbox
							color="primary"
							checked={includeUndeclared}
							onChange={(e) => onIncludeUndeclaredChange(e.target.checked)}
						/>
					}
					label="Include Undeclared delays"
				/>
			</Grid>
		</Grid>
	);
};
