import { get } from "lodash-es";
import { DelayCode } from "../../models/Delay";
import { useConfigurationDelayCodeContext } from "../context/ConfigurationDelayCodeContext";
import { getDelays } from "../repositories/ConfigurationDelayRepository";
import { TransformDelayModelData } from "./TransformAssetModelData";

type Props = {
	setSearchError: (err: string) => void;
	setAllDelayLoading: (loader: boolean) => void;
};

export const useSearchDelays = ({
	setSearchError,
	setAllDelayLoading,
}: Props) => {
	const {
		actions: { setAllDelayNodes, setAllDelayCodes },
	} = useConfigurationDelayCodeContext();
	const searchDelays = (assetId?: number) => {
		getDelays(assetId).then((res: any) => {
			if (res.ok) {
				let mydataSource: any[] = [];
				const rows = get(res, "data.tables[0].rows", []);
				rows.forEach((row: DelayCode) => {
					const code = TransformDelayModelData(row);
					mydataSource = [...mydataSource, code];
				});
				setAllDelayCodes(rows);
				setAllDelayNodes(mydataSource);
				setAllDelayLoading(false);
			} else setSearchError(res.message);
		});
	};
	return { searchDelays };
};
