import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { DelayCode } from "../../models/Delay";
import { AssetParameters } from "../../models/TreeParameter";

export const TransformAssetModelData = (node: Asset): AssetParameters => {
	const model: AssetParameters = {
		id: node.AssetId,
		parent: node.ParentAssetId ? node.ParentAssetId : 0,
		text: node.AssetName,
		data: {
			fileType: "csv",
			fileSize: "",
		},
		droppable: node.IsBranch,
	};
	return model;
};

export const TransformDelayModelData = (node: DelayCode): AssetParameters => {
	const model: AssetParameters = {
		id: node.DelayCodeId,
		parent: node.ParentDelayCodeId ? node.ParentDelayCodeId : 0,
		text: node.DelayCodeName,
		data: {
			fileType: "csv",
			fileSize: "",
		},
		droppable: node.IsBranch,
	};
	return model;
};
