import { MESApiService, getMomentTz, spParameter } from "@dexteel/mesf-core";
const moment = getMomentTz();

export const getShiftsAndCrews = async () => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	return await apiService.callV2("[MES].[GetShiftsAndCrews]", parameters);
};

export const getEntries = async (
	EntryId?: number | null,
	SearchText?: string | null,
	AssetId?: number | null,
	ShiftId?: number | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "EntryId", value: EntryId as number });
	parameters.push({
		name: "SearchText",
		value: SearchText === "" ? null : (SearchText as string),
	});
	parameters.push({ name: "AssetId", value: AssetId as number });
	parameters.push({ name: "ShiftId", value: ShiftId as number });

	return await apiService.callV2("[LB].[GetEntries]", parameters);
};

export const getEntry = async (EntryId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "EntryId", value: EntryId as number });
	return await apiService.callV2("[LB].[GetEntries]", parameters);
};

export const upsertEntry = async (
	EntryId: number | null,
	ShiftId: number,
	AssetId: number | null,
	Title: string,
	Content: string,
	CreateTimestamp: Date,
	Author: string,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "EntryId", value: EntryId },
		{ name: "ShiftId", value: ShiftId },
		{ name: "AssetId", value: AssetId },
		{ name: "Title", value: Title },
		{ name: "Content", value: Content },
		{
			name: "CreateTimestamp",
			value: moment.utc(CreateTimestamp).local().toISOString(),
		},
		{
			name: "UpdateTimestamp",
			value: moment.utc(new Date()).local().toISOString(),
		},
		{ name: "Author", value: Author },
	];

	return await apiService.callV2("[LB].[UpsertEntry]", parameters);
};

export const deleteEntry = async (EntryId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "EntryId", value: EntryId }];

	return await apiService.callV2("[LB].[DeleteEntry]", parameters);
};
