import { DelayCategorySelector } from "@/controls/selectors/DelayCategorySelector";
import { getDelayById } from "@/pages/caster/repositories/ProductionRepository";
import CustomTimeSlider from "@/pages/delays/CustomTimeSlider";
import { TransformDelayModelData } from "@/pages/delays/codes/hooks/TransformAssetModelData";
import { getDelays } from "@/pages/delays/codes/repositories/ConfigurationDelayRepository";
import { DelayByShift } from "@/pages/delays/delays-manager/models/DelayByShift";
import { splitDelay } from "@/pages/delays/delays-manager/repositories/DelaysManagerRepository";
import { DelayCode } from "@/pages/delays/models/Delay";
import { AssetParameters } from "@/pages/delays/models/TreeParameter";
import {
	ErrorModal,
	MesfModal,
	TreePickerControl,
	dxtToLocalServerTime,
} from "@dexteel/mesf-core";
import {
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	TextField,
	Typography,
	makeStyles,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { get } from "lodash-es";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTreeBuilder } from "../../hooks/useTreeBuilder";

const useStyles = makeStyles((theme) => ({
	sliderContainer: {
		position: "relative",
		marginBottom: theme.spacing(4),
		paddingTop: theme.spacing(3),
	},
	durationLabel: {
		position: "absolute",
		top: 0,
		transform: "translateX(-50%)",
		textAlign: "center",
		fontSize: "0.75rem",
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		padding: "2px 4px",
		borderRadius: "4px",
		fontWeight: "bold",
	},
	commentsInput: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
		"& .MuiOutlinedInput-input": {
			height: "100px !important",
		},
	},
	infoLabel: {
		fontSize: 12,
		color: "#757575",
	},
}));

type Props = {
	show: boolean;
	onHide: () => void;
	delayId: number | null;
	refreshData: Function;
};

interface CombinedDelayForm {
	delay1: DelayByShift;
	delay2: DelayByShift;
}

const formatDuration = (duration: number): string => {
	const hours = Math.floor(duration / (1000 * 60 * 60));
	const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((duration % (1000 * 60)) / 1000);
	return `${hours} hs ${minutes} min ${seconds} sec`;
};

export const SplitDelays = ({ show, onHide, delayId, refreshData }: Props) => {
	const minDelayDuration = 10;
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [sliderValue, setSliderValue] = useState<Date | null>(null);
	const [open, setOpen] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [delay, setDelay] = useState<DelayByShift | null>(null);
	const [allDelayCodes, setAllDelayCodes] = useState<DelayCode[]>([]);

	const { loadFilterDelays, delayCodes } = useTreeBuilder({
		allDelayCodes,
	});

	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		reset,
		formState: { errors },
	} = useForm<CombinedDelayForm>({});

	const fetchDelayById = async () => {
		setIsLoading(true);
		setDelay(null);
		try {
			const response = await getDelayById(Number(delayId));
			if (response && response.ok) {
				const fetchedDelay = get(response, "data.tables[0].rows[0]", null);
				if (fetchedDelay) {
					setDelay(fetchedDelay);
				} else {
					setError("Delay not found.");
				}
			} else {
				setError("Failed to fetch delay details.");
			}
		} catch {
			setError("An error occurred while fetching the delay.");
		} finally {
			setIsLoading(false);
		}
	};

	const searchDelays = async (assetId?: number) => {
		setIsLoading(true);
		try {
			const res = await getDelays(assetId);
			if (res.ok) {
				let mydataSource: AssetParameters[] = [];
				const rows = get(res, "data.tables[0].rows", []);
				rows.forEach((row: DelayCode) => {
					const code = TransformDelayModelData(row);
					mydataSource = [...mydataSource, code];
				});
				setAllDelayCodes(rows);
				setIsLoading(false);
			} else {
				setError(res.message || "Failed to fetch delays data.");
			}
		} catch (error) {
			setError("An unexpected error occurred while fetching delays.");
		} finally {
			setIsLoading(false);
		}
	};

	const onSubmit: SubmitHandler<CombinedDelayForm> = async (data) => {
		if (
			data.delay1.End?.getTime()! - data.delay1.Start?.getTime()! <=
				minDelayDuration * 1000 ||
			data.delay2.End?.getTime()! - data.delay2.Start?.getTime()! <=
				minDelayDuration * 1000
		) {
			setOpenError(true);
		} else {
			const resp = await splitDelay(data.delay1, data.delay2);
			if (resp.ok) {
				setOpen(true);
				await refreshData();
			} else {
				setError(resp.message);
			}
			onHide();
		}
	};

	useEffect(() => {
		if (show && delayId) {
			fetchDelayById();
		}
	}, [show, delayId]);

	useEffect(() => {
		if (show && delay) {
			reset();
			(async () => {
				setIsLoading(true);
				searchDelays(delay?.AssetId ? delay.AssetId : undefined);
				const startDate = moment.utc(delay.Start).toDate();
				const endDate = moment.utc(delay.End).toDate();
				const middleDate = new Date(
					(startDate.getTime() + endDate.getTime()) / 2,
				);
				setValue("delay1.DelayId", delay.DelayId);
				setValue("delay1.Start", startDate);
				setValue("delay1.End", middleDate);
				setValue("delay2.Start", middleDate);
				setValue("delay2.End", endDate);
				setValue("delay1.DelayCategoryId", delay.DelayCategoryId);
				setValue("delay1.Comments", delay.Comments);
				setValue("delay1.DelayCodeId", delay.DelayCodeId);
				setSliderValue(middleDate);
				setIsLoading(false);
			})();
		}
	}, [show, delay, delayId]);

	useEffect(() => {
		if (allDelayCodes.length > 0) {
			loadFilterDelays();
			const delayCode = allDelayCodes.find(
				(node: DelayCode) => node.DelayCodeId === delay?.DelayCodeId,
			);
			if (delayCode) {
				setValue("delay1.DelayCodeName", delayCode.DelayCodeName);
			}
		}
	}, [allDelayCodes]);

	const [selectedTime, setSelectedTime] = useState(delay?.Start);

	if (!delay || !delayCodes) {
		return null;
	}

	return (
		<>
			<MesfModal
				title="SPLIT DELAY"
				open={show}
				handleClose={() => onHide()}
				id="create-grade-modal"
				maxWidth="lg"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MesfModal.Content dividers>
						<Grid container justifyContent="center">
							<Grid
								md={10}
								style={{ marginBottom: 10 }}
								className={classes.sliderContainer}
							>
								<Typography
									className={classes.durationLabel}
									style={{ left: "25%", color: "#3f51b5" }}
								>
									{formatDuration(
										getValues("delay1.End")?.getTime()! -
											getValues("delay1.Start")?.getTime()!,
									)}
								</Typography>
								<Typography
									className={classes.durationLabel}
									style={{ left: "75%", color: "green" }}
								>
									{formatDuration(
										getValues("delay2.End")?.getTime()! -
											getValues("delay2.Start")?.getTime()!,
									)}
								</Typography>
								<CustomTimeSlider
									startTime={moment.utc(delay?.Start).toDate()}
									endTime={moment.utc(delay?.End).toDate()}
									initialValue={sliderValue}
									onTimeChange={(newTime: Date) => {
										setSelectedTime(newTime);
										setValue("delay1.End", newTime);
										setValue("delay2.Start", newTime);
									}}
								/>
							</Grid>
							<Grid container md={5}>
								<Typography>Delay 1</Typography>
								<Grid container md={12} spacing={1} style={{ marginTop: 10 }}>
									<Grid item md={6}>
										<Controller
											name="delay1.Start"
											control={control}
											rules={{ required: "Delay code is required" }}
											render={({ field }) => (
												<TextField
													label="Start"
													variant="outlined"
													value={dxtToLocalServerTime(field?.value, "HH:mm:ss")}
													error={!!errors.delay1?.Start}
													onChange={() => {}}
													disabled
													fullWidth
													margin="dense"
													autoComplete="off"
												/>
											)}
										/>
									</Grid>
									<Grid item md={6}>
										<Controller
											name="delay1.DelayCodeName"
											control={control}
											render={({ field }) => (
												<>
													<TreePickerControl
														inputTitle="Delay"
														title="Delay"
														dataSource={delayCodes}
														value={delay?.DelayCodeId || 0}
														description={field.value ? field?.value : ""}
														onSelect={(value, description) => {
															setValue("delay1.DelayCodeId", value);
															setValue("delay1.DelayCodeName", description);
															setValue(
																"delay1.DelayCategoryId",
																allDelayCodes.find(
																	(dCodes: DelayCode) =>
																		value === dCodes.DelayCodeId,
																)?.DelayCategoryId || null,
															);
														}}
														selectBranch={true}
														selectActive={false}
														selectInternal={false}
													/>
													{errors.delay1?.DelayCodeId && (
														<span>{errors.delay1?.DelayCodeId.message}</span>
													)}
												</>
											)}
										/>
									</Grid>
									<Grid item md={6}>
										<Controller
											name="delay1.End"
											control={control}
											render={({ field }) => (
												<TextField
													label="End"
													variant="outlined"
													value={dxtToLocalServerTime(field?.value, "HH:mm:ss")}
													disabled
													fullWidth
													margin="dense"
													autoComplete="off"
												/>
											)}
										/>
									</Grid>
									<Grid item md={6} style={{ marginTop: 8 }}>
										<Controller
											name="delay1.DelayCategoryId"
											control={control}
											render={({ field }) => (
												<DelayCategorySelector
													value={field.value}
													onChange={field.onChange}
													assetId={delay?.AssetId || null}
													error={errors.delay1?.DelayCategoryId?.message || ""}
												/>
											)}
										/>
									</Grid>
									<Grid item md={12}>
										<Controller
											name="delay1.Comments"
											control={control}
											render={({ field }) => (
												<>
													<TextField
														label="Comments"
														className={classes.commentsInput}
														variant="outlined"
														error={!!errors.delay1?.Comments}
														value={field?.value}
														fullWidth
														maxRows={5}
														multiline
														margin="dense"
														autoComplete="off"
														onChange={(event) => {
															if (event.target.value.length <= 200) {
																setValue("delay1.Comments", event.target.value);
															}
														}}
													/>
													{(getValues("delay1.Comments")?.length as number) !==
														200 && (
														<span
															className={classes.infoLabel}
															style={{ marginLeft: 5 }}
														>
															{`${
																getValues("delay1.Comments")?.length ?? 0
															}/200 max.`}
														</span>
													)}
													{(getValues("delay1.Comments")?.length as number) ==
														200 && (
														<span
															className={classes.infoLabel}
															style={{ marginLeft: 5 }}
														>
															Max. 200
														</span>
													)}
												</>
											)}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid md={1} />
							<Grid container md={5}>
								<Typography>Delay 2</Typography>
								<Grid container md={12} spacing={1} style={{ marginTop: 10 }}>
									<Grid item md={6}>
										<Controller
											name="delay2.Start"
											control={control}
											render={({ field }) => (
												<TextField
													label="Start"
													variant="outlined"
													value={dxtToLocalServerTime(field?.value, "HH:mm:ss")}
													error={!!errors.delay2?.Start}
													disabled
													fullWidth
													margin="dense"
													autoComplete="off"
												/>
											)}
										/>
									</Grid>
									<Grid item md={6}>
										<Controller
											name="delay2.DelayCodeName"
											control={control}
											render={({ field }) => (
												<>
													<TreePickerControl
														inputTitle="Delay"
														title="Delay"
														dataSource={delayCodes}
														value={delay?.DelayCodeId || 0}
														description={field.value ? field?.value : ""}
														onSelect={(value, description) => {
															setValue("delay2.DelayCodeId", value);
															setValue("delay2.DelayCodeName", description);
															setValue(
																"delay2.DelayCategoryId",
																allDelayCodes.find(
																	(dCodes: DelayCode) =>
																		value === dCodes.DelayCodeId,
																)?.DelayCategoryId || null,
															);
														}}
														selectBranch={true}
														selectActive={false}
														selectInternal={false}
													/>
													{errors.delay2?.DelayCodeId && (
														<span>{errors.delay2?.DelayCodeId.message}</span>
													)}
												</>
											)}
										/>
									</Grid>

									<Grid item md={6}>
										<Controller
											name="delay2.End"
											control={control}
											render={({ field }) => (
												<TextField
													label="End"
													variant="outlined"
													value={dxtToLocalServerTime(field?.value, "HH:mm:ss")}
													disabled
													fullWidth
													margin="dense"
													autoComplete="off"
												/>
											)}
										/>
									</Grid>
									<Grid item md={6} style={{ marginTop: 8 }}>
										<Controller
											name="delay2.DelayCategoryId"
											control={control}
											render={({ field }) => (
												<DelayCategorySelector
													value={field.value}
													onChange={field.onChange}
													assetId={delay?.AssetId || null}
													error={errors.delay2?.DelayCategoryId?.message || ""}
												/>
											)}
										/>
									</Grid>
									<Grid item md={12}>
										<Controller
											name="delay2.Comments"
											control={control}
											render={({ field }) => (
												<>
													<TextField
														label="Comments"
														className={classes.commentsInput}
														variant="outlined"
														error={!!errors.delay2?.Comments}
														value={field?.value}
														fullWidth
														maxRows={5}
														multiline
														margin="dense"
														autoComplete="off"
														onChange={(event) => {
															if (event.target.value.length <= 200) {
																setValue("delay2.Comments", event.target.value);
															}
														}}
													/>
													{(getValues("delay2.Comments")?.length as number) !==
														200 && (
														<span
															className={classes.infoLabel}
															style={{ marginLeft: 5 }}
														>
															{`${
																getValues("delay2.Comments")?.length ?? 0
															}/200 max.`}
														</span>
													)}
													{(getValues("delay2.Comments")?.length as number) ==
														200 && (
														<span
															className={classes.infoLabel}
															style={{ marginLeft: 5 }}
														>
															Max. 200
														</span>
													)}
												</>
											)}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</MesfModal.Content>
					<MesfModal.Actions style={{ padding: "20px 90px 30px" }}>
						<Grid container spacing={1} justifyContent="flex-end">
							<Grid item md={2} xs={12}>
								<Button
									fullWidth
									variant="contained"
									color="default"
									onClick={() => onHide()}
									size="medium"
								>
									Cancel
								</Button>
							</Grid>
							<Grid item md={2} xs={12}>
								<Button
									fullWidth
									startIcon={isLoading && <CircularProgress size="1rem" />}
									disabled={isLoading}
									variant="contained"
									color="primary"
									type="submit"
									size="medium"
								>
									Split
								</Button>
							</Grid>
						</Grid>
					</MesfModal.Actions>
				</form>
			</MesfModal>
			<Snackbar
				open={openError}
				autoHideDuration={2500}
				onClose={() => setOpenError(false)}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="error"
					onClose={() => setOpenError(false)}
				>
					The minimum duration allowed is {minDelayDuration} seconds
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={open}
				autoHideDuration={2500}
				onClose={() => setOpen(false)}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity="success"
					onClose={() => setOpen(false)}
				>
					The operation was completed successfully
				</MuiAlert>
			</Snackbar>
			<ErrorModal
				error={error}
				onHide={() => setError("")}
				title="Error Creating Grade"
			/>
		</>
	);
};
