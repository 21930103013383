export const StatusIndicator = (props: any) => {
	const getStatusColor = () => {
		if (!props.value?.lastTappedDate) return "#a0a0a0";
		const diffDays = props.value.diffDays;
		if (diffDays <= 2) return "#4caf50";
		if (diffDays <= 5) return "#ffd700";
		return "#a0a0a0";
	};

	const getTitle = () => {
		if (!props.value?.lastTappedDate)
			return "Ladle not used in the last 5 days";
		const diffDays = props.value.diffDays;
		if (diffDays <= 2) return "Ladle used in the last 2 days";
		if (diffDays <= 5) return "Ladle used in the last 5 days";
		return "Ladle not used in the last 5 days";
	};

	return (
		<div
			title={getTitle()}
			style={{
				width: "15px",
				height: "15px",
				borderRadius: "50%",
				backgroundColor: getStatusColor(),
				margin: "auto",
				border: "1px solid rgba(0,0,0,0.2)",
			}}
		/>
	);
};
