import { Entry } from "./Entry";

export const ENTRY_INITIAL_VALUES: Entry = {
	EntryId: 0,
	ShiftId: 0,
	AssetId: null,
	Title: "",
	Content: "",
	CreateTimestamp: new Date(),
	UpdateTimestamp: new Date(),
	LogEntryTypeCode: "M",
	Shift: null,
	Crew: null,
	Author: null,
	UpdatedBy: null,
};
