import { getDelayAreas } from "@/pages/delays/delays-report/repositories/DelaysReportRepository";
import { ErrorModal } from "@dexteel/mesf-core";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import {
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	styled,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

const StyledFormControl = styled(FormControl)({
	width: "100%",

	"& .MuiInputBase-root": {
		height: "40px",
		borderRadius: "4px",
		border: "1px solid rgba(0, 0, 0, 0.23)",
		"&:hover": {
			borderColor: "rgba(0, 0, 0, 0.87)",
		},
		"&:before, &:after": {
			display: "none",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
		},
	},
	"& .MuiInputLabel-root": {
		transform: "translate(14px, 8px) scale(1)",
		"&.MuiInputLabel-shrink": {
			transform: "translate(14px, -6px) scale(0.75)",
			backgroundColor: "white",
			padding: "0 4px",
		},
	},
	"& .MuiSelect-select": {
		paddingTop: "0",
		paddingBottom: "0",
		height: "100%",
		display: "flex",
		alignItems: "center",
	},
});
type Props = {
	value: number | null;
	onChange: (assetId: number | null) => void;
	title?: string;
	nullValue?: string;
	onlyAssets?: boolean;
	customStyles?: React.CSSProperties;
	disabled?: boolean;
};

export const AssetsFilter: React.FC<Props> = ({
	value,
	onChange,
	title = "Assets",
	nullValue = "All",
	onlyAssets = false,
	customStyles = {},
	disabled = false,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [assets, setAssets] = useState<
		{ AssetId: number; AssetName: string }[]
	>([]);

	const getDelayAreasFromAPI = async () => {
		setIsLoading(true);
		const res = await getDelayAreas();
		if (res.ok) {
			const data = res.data.tables[0].rows || [];
			if (onlyAssets) {
				const assetsWithoutEAF = data.filter(
					(asset: Asset) => asset.AssetName !== "EAF",
				);
				setAssets(assetsWithoutEAF);
			} else {
				setAssets(data);
			}
		} else {
			setError(res.message);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		(async () => {
			await getDelayAreasFromAPI();
		})();
	}, []);

	let options = [];
	if (nullValue) {
		options.push({ AssetId: -1, AssetName: nullValue });
	}
	options.push(...assets);

	return (
		<>
			<StyledFormControl variant="outlined">
				<InputLabel id="delay-area-label">{title}</InputLabel>
				<Select
					disabled={disabled}
					labelId="delay-area-label"
					value={value}
					endAdornment={isLoading && <CircularProgress size={20} />}
					label={title}
					style={customStyles}
					onChange={(e) => {
						const value = e.target.value;
						onChange(value ? Number(value) : null);
					}}
				>
					{options?.map((area) => (
						<MenuItem key={area.AssetId} value={area.AssetId}>
							{area.AssetName}
						</MenuItem>
					))}
				</Select>
			</StyledFormControl>

			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
