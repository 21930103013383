import { ResponseMESF, useUTLSettingsContext } from "@dexteel/mesf-core";
import { Box, Paper, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { get } from "lodash-es";
import { useMemo, useState } from "react";
import { useDataEntryContext } from "../../../context/reducer.provider";
import { useTimerContext } from "../../../context/timer.provider";
import { useEAFDataEntryActions } from "../../../hooks/useEAFDataEntryActions";
import { readChargesFromPLC } from "../../../repositories/eaf.repositories";
import { CreateCharge } from "../../modals/charge/CreateCharge";
import { EditCharge } from "../../modals/charge/EditCharge";
import { AgGridFooterActions } from "../components/AgGridFooterActions";
import { useEAFDETablesStyles } from "../styles/useEAFDETablesStyles";
import { useTableData } from "./hooks/useChargesDataTable";
export const TableCharges = () => {
	const { state } = useUTLSettingsContext();

	const MAX_CHARGES = get(state.settings, "MAX_CHARGES", 10);

	const {
		state: { deProcess },
		actions: { setErrorMessage },
	} = useDataEntryContext();
	const { deleteCharge, refreshData } = useEAFDataEntryActions();
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [selectedCharge, setSelectedCharge] = useState<any>();

	const charges = deProcess?.charges;
	const rowCount = charges ? charges.length : 0;
	const classes = useEAFDETablesStyles({ rowCount });
	const { pause, resume } = useTimerContext();
	const hasMaxCharges = (charges && charges.length >= MAX_CHARGES) || false;

	const editCharge = (ChargeId: number) => {
		pause();
		const charge = charges?.find((p) => p.ChargeId === ChargeId);
		setSelectedCharge(charge);
		setShowEditModal(true);
	};
	const readFromPLC = async () => {
		const ProcessId = deProcess?.process?.ProcessId;
		if (ProcessId) {
			const resp = await readChargesFromPLC({ ProcessId });
			if (resp.ok) {
				await refreshData();
			} else {
				setErrorMessage(resp.message);
			}
			return resp;
		} else {
			setErrorMessage("Process not found");
			return { ok: false, message: "Process not found" } as ResponseMESF;
		}
	};

	const { columns, defaultColDef } = useTableData({
		deleteCharge,
		editCharge,
	});
	const totalScrap = useMemo(() => {
		return charges?.reduce((sum, charge) => sum + (charge.Weight || 0), 0) || 0;
	}, [charges]);
	return (
		<>
			<Paper className={classes.root} elevation={0}>
				<Box
					padding={1}
					className={`ag-theme-balham ${classes.agGridBox} ${classes.dynamicHeight}`}
					style={{ position: "relative" }}
				>
					<AgGridReact
						rowSelection="single"
						suppressRowClickSelection={true}
						pagination={false}
						rowHeight={50}
						headerHeight={35}
						singleClickEdit={false}
						tooltipShowDelay={0}
						tooltipHideDelay={2000}
						rowData={charges}
						columnDefs={columns}
						defaultColDef={defaultColDef}
						domLayout="normal"
					/>
					<div>
						<Box
							display="flex"
							justifyContent="center"
							width="100%"
							mt={2}
							style={{ position: "absolute", right: "4%" }}
						>
							<Typography style={{ cursor: "default", userSelect: "none" }}>
								Total Scrap: <strong> {totalScrap}</strong> lbs
							</Typography>
						</Box>
						<AgGridFooterActions
							hasMaxItems={hasMaxCharges}
							showCreateModal={showCreateModal}
							setShowCreateModal={setShowCreateModal}
							readFromPLC={readFromPLC}
							table="charges"
						/>
					</div>
				</Box>
			</Paper>
			{deProcess?.process.Start ? (
				<>
					<CreateCharge
						show={showCreateModal}
						onHide={() => {
							resume();
							setShowCreateModal(false);
						}}
						date={
							charges && charges[0]
								? charges[0].Timestamp
								: deProcess?.process.Start
						}
						limitDates={{
							start: deProcess?.process.Start,
							end: deProcess?.process.End,
						}}
					/>

					<EditCharge
						show={showEditModal}
						onHide={() => {
							resume();
							setShowEditModal(false);
						}}
						charge={selectedCharge}
						limitDates={{
							start: deProcess?.process.Start,
							end: deProcess?.process.End || null,
						}}
					/>
				</>
			) : null}
		</>
	);
};
