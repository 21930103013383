import { DelayCode } from "../../../models/Delay";
import { TreeCode } from "../../../models/TreeCode";
import { useConfigurationDelayCodeContext } from "../../context/ConfigurationDelayCodeContext";

export const useDelayCodes = () => {
	const {
		state: { allDelayCodes },
	} = useConfigurationDelayCodeContext();
	const {
		actions: { setDelayCodes },
	} = useConfigurationDelayCodeContext();

	const buildTreeDelays = (nodes: TreeCode[]) => {
		if (nodes === undefined) return;
		//tree build functions
		const idMapping = nodes.reduce((acc: any, el: any, i: number) => {
			acc[el.id] = i;
			return acc;
		}, {});
		let root;
		nodes.forEach((el) => {
			// Handle the root element
			if (el.parentId === null) {
				root = el;
				return;
			}
			// Use our mapping to locate the parent element in our data array
			const parentEl = nodes[idMapping[el.parentId]];
			// Handle the case where the element has no parent
			if (!parentEl) {
				console.log(el);
				return;
			}
			// Add our current el to its parent's `children` array
			if (!parentEl["children"]) {
				parentEl["children"] = [];
			}
			parentEl["children"] = [...(parentEl["children"] || []), el];
		});
		setDelayCodes(root as any);
	};

	const loadFilterDelays = () => {
		const nodes = allDelayCodes.map(
			(del: DelayCode) =>
				({
					id: del.DelayCodeId,
					parentId: del.ParentDelayCodeId,
					name: del.DelayCodeName,
					isLeaf: !!del.IsLeaf,
					isActive: true,
					children: [],
				}) as TreeCode,
		);
		buildTreeDelays(nodes);
	};

	return { loadFilterDelays };
};
