import { useSelectorStyles } from "@/pages/delays/delays-manager/styles/selectorStyles";
import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { getCatalogCategories } from "../../pages/delays/categories/repositories/CatalogCategoriesRepository";
import { CatalogCategory } from "../../pages/delays/models/CatalogCategory";

type DelayCategorySelectorProps = {
	value: number | null;
	onChange: (value: number | null) => void;
	disabled?: boolean;
	assetId: number | null;
	error?: string;
};

const useStyles = makeStyles(() => ({
	colorBadge: {
		width: "26px",
		height: "26px",
		borderRadius: "4px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "#fff",
		fontWeight: "bold",
		marginRight: "8px",
		marginLeft: "8px",
	},
	optionContainer: {
		display: "flex",
		alignItems: "center",
	},
}));

export const DelayCategorySelector: React.FC<DelayCategorySelectorProps> = ({
	value,
	onChange,
	disabled = false,
	assetId,
	error = "",
}) => {
	const [categories, setCategories] = useState<CatalogCategory[]>([]);
	const [fetchError, setFetchError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const classes = useStyles();
	const selectorClasses = useSelectorStyles();

	useEffect(() => {
		setIsLoading(true);
		getCatalogCategories(assetId, true)
			.then((resp) => {
				if (resp.ok) {
					setCategories(get(resp.data, "tables[0].rows", []));
				} else {
					setFetchError(resp.message);
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setFetchError(error.message);
				setIsLoading(false);
			});
	}, [assetId]);

	return (
		<>
			<Autocomplete
				id="clear-on-escape"
				clearOnEscape
				options={categories ?? []}
				getOptionSelected={(option, val) =>
					option.DelayCategoryId === val.DelayCategoryId
				}
				getOptionLabel={(option) => option.DelayCategoryName}
				onChange={(event, newValue) => {
					onChange(newValue ? newValue.DelayCategoryId : null);
				}}
				renderOption={(option) => (
					<div className={classes.optionContainer}>
						<span
							className={classes.colorBadge}
							style={{ backgroundColor: option.DelayCategoryColor }}
						>
							{option.DelayCategoryName.charAt(0).toUpperCase()}
						</span>
						{option.DelayCategoryName}
					</div>
				)}
				disabled={disabled}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Category"
						variant="outlined"
						error={!!error}
						size="small"
						fullWidth
						helperText={error}
						className={selectorClasses.inputBase}
						InputProps={{
							...params.InputProps,
							startAdornment: value ? (
								<span
									className={classes.colorBadge}
									style={{
										backgroundColor:
											categories.find(
												(category) => category.DelayCategoryId === value,
											)?.DelayCategoryColor || "#ccc",
									}}
								>
									{categories
										.find((category) => category.DelayCategoryId === value)
										?.DelayCategoryName.charAt(0)
										.toUpperCase()}
								</span>
							) : null,
							endAdornment: (
								<>
									{isLoading ? (
										<CircularProgress color="inherit" size={20} />
									) : (
										params.InputProps.endAdornment
									)}
								</>
							),
						}}
					/>
				)}
				value={
					categories.find((category) => category.DelayCategoryId === value) ??
					null
				}
			/>
			<ErrorModal error={fetchError} onHide={() => setFetchError("")} />
		</>
	);
};
