import { get } from "react-hook-form";
import { useDelaysParetoContext } from "../context/DelaysParetoContext";
import { getDelaysForPareto } from "../repositories/DelaysParetoRepository";

type Props = {
	setIsLoading: (isLoading: boolean) => void;
	setError: (err: string) => void;
};

export const useSearchDelaysForPareto = ({ setIsLoading, setError }: Props) => {
	const {
		actions: { setDelaysListForPareto },
	} = useDelaysParetoContext();

	return async (
		startDate?: Date,
		endDate?: Date,
		delayAreaAssetId?: number | null,
		includeMicroDelays?: boolean,
		includeUndeclared?: boolean,
	) => {
		setIsLoading(true);
		const res = await getDelaysForPareto(
			startDate,
			endDate,
			undefined,
			undefined,
			undefined,
			undefined,
			delayAreaAssetId as number,
			includeMicroDelays,
			includeUndeclared,
		);

		if (res.ok) {
			const rowsSource = get(res, "data.tables[0].rows", []);
			setDelaysListForPareto(rowsSource);
			setIsLoading(false);
		} else {
			setIsLoading(false);
			setError(res.message);
		}
	};
};
