import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { getLadleStatusReport } from "../repositories/LadleStatusRepository";
import { TableLadleStatus } from "./components/TableLadleStatus";

export const LadleStatusPage = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState("");
	const [ladleStatus, setLadleStatus] = useState<any>();

	const getDataFromAPI = async () => {
		setIsLoading(true);
		try {
			const response = await getLadleStatusReport();

			if (response.ok) {
				const data = response.data.tables[0];
				setLadleStatus(data.rows);
				setIsLoading(false);
			} else {
				setIsLoading(false);
				setError(response.message);
			}
		} catch (e: any) {
			setError(e);
		}
	};

	useEffect(() => {
		getDataFromAPI();
	}, []);

	if (!isLoading) {
		return (
			<>
				<LazyBackDropLoading open={isLoading} />
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0, padding: "15px" }}>
						<Grid item md={12} xs={12}>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
							>
								<Typography variant="h5" style={{ fontWeight: 600 }}>
									Ladle Status
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
							item
							md={12}
							xs={12}
						>
							<TableLadleStatus ladleStatus={ladleStatus} />
						</Grid>
					</Grid>
				</Grid>
				<ErrorModal error={error} onHide={() => setError("")} />
			</>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<LazyLoading />
		</div>
	);
};
