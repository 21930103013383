import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { CategoriesProvider } from "../categories/context/CategoriesContext";
import { CatalogDelayPage } from "./CatalogDelayCodePage";
import { ConfigurationAssetProvider } from "./context/ConfigurationAssetContext";
import { ConfigurationDelayCodeProvider } from "./context/ConfigurationDelayCodeContext";
import { getAssets } from "./repositories/ConfigurationAssetRepository";

type Props = {
	delayAreaAssetName?: string;
};

const DelayCodePage = (props: Props) => {
	const [delayAreaAsset, setDelayAreaAsset] = useState<Asset[]>([]);
	const [assetId, setAssetId] = useState<number | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	const getDelayAreaAsset = async () => {
		try {
			setIsLoading(true);
			const response = await getAssets();
			if (response.ok) {
				const assets = get(response, "data.tables[0].rows", []);
				setDelayAreaAsset(assets);
			} else {
				setError("Error fetching assets");
			}
		} catch (err) {
			setError("Error fetching assets");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (delayAreaAsset.length > 0) {
			const foundAssetId =
				delayAreaAsset.find(
					(asset: Asset) => asset.AssetName === props.delayAreaAssetName,
				)?.AssetId ?? null;
			setAssetId(foundAssetId);
		}
	}, [delayAreaAsset, props.delayAreaAssetName]);

	useEffect(() => {
		getDelayAreaAsset();
	}, []);

	if (!assetId || isLoading) return null;
	if (error) return <div>{error}</div>;

	return (
		<ConfigurationDelayCodeProvider>
			<CategoriesProvider>
				<CatalogDelayPage
					delayAreaAssetId={assetId}
					delayAreaAssetName={props.delayAreaAssetName}
				/>
			</CategoriesProvider>
		</ConfigurationDelayCodeProvider>
	);
};

export default DelayCodePage;
