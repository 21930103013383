import { ConfigurationDelayCodeProvider } from "../delays/codes/context/ConfigurationDelayCodeContext";
import { CasterDashboardProvider } from "./CasterDashboardContext";
import { MeltshopDashboardPage } from "./MeltshopDashboardPage";

type Props = {};
const MeltshopDashboard = (props: Props) => {
	return (
		<CasterDashboardProvider>
			<MeltshopDashboardPage />
		</CasterDashboardProvider>
	);
};
export default MeltshopDashboard;
