import { getDelayCodes } from "@/pages/delays/delays-manager/repositories/DelaysManagerRepository";
import { useSelectorStyles } from "@/pages/delays/delays-manager/styles/selectorStyles";
import { DelayCode } from "@/pages/delays/models/Delay";
import { TreeNode, TreePickerControlV2 } from "@dexteel/mesf-core";
import { CircularProgress, TextField, makeStyles } from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useMemo, useState } from "react";

const useStyles = makeStyles(() => ({
	treePickerWrapper: {
		"& .MuiGrid-root.MuiGrid-container": {
			"& .MuiGrid-item": {
				"& .MuiFormControl-root": {
					"& .MuiInputBase-root": {
						"& .MuiOutlinedInput-notchedOutline": {
							border: "2px solid #3F51B5",
							height: "57px",
						},
					},
				},
			},
		},
	},
}));

export interface NodeTreeCodes {
	id: number;
	isActive: boolean;
	isLeaf: boolean;
	name: string;
	parentId: number | null;
	children: NodeTreeCodes[] | null;
}

const buildAssetTreeCodes = (nodes: NodeTreeCodes[]): TreeNode => {
	//tree build functions
	const idMapping = nodes.reduce((acc: any, el: NodeTreeCodes, i: number) => {
		// console.log(acc)
		acc[el.id] = i;
		return acc;
	}, {});
	let root;
	nodes.forEach((el: NodeTreeCodes) => {
		// Handle the root element
		if (el.parentId === null) {
			root = el;
			return;
		}
		// Use our mapping to locate the parent element in our data array
		const parentEl = nodes[idMapping[el.parentId]];
		// Add our current el to its parent's `children` array
		if (parentEl) {
			parentEl.children = [...(parentEl?.children || []), el];
		}
	});
	// @ts-ignore
	return root;
};

export const DelayCodePicker = ({
	value,
	onChange,
	disabled,
	error,
	assetId,
}: {
	value: number | null;
	onChange: (value: number | null, delayCode: DelayCode) => void;
	disabled?: boolean;
	error?: string;
	assetId: number | null;
}) => {
	const [delayCodes, setDelayCodes] = useState([] as DelayCode[]);
	const [delayCodesTree, setDelayCodesTree] = useState<TreeNode | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [internalError, setError] = useState<string | null>(null);

	const classes = useStyles();
	const selectorClasses = useSelectorStyles();

	const fetchDelayCodes = async () => {
		setIsLoading(true);
		try {
			const response = await getDelayCodes(assetId);
			if (response && response.ok) {
				const rows = get(response, "data.tables[0].rows", []);
				setDelayCodes(rows);

				const tree = buildAssetTreeCodes(
					rows.map(
						(del: DelayCode) =>
							({
								id: del.DelayCodeId,
								parentId: del.ParentDelayCodeId,
								name: del.DelayCodeName,
								isLeaf: !!del.IsLeaf,
								isActive: true,
								children: [],
								delayCategoryId: del.DelayCategoryId,
							}) as NodeTreeCodes,
					),
				);
				setDelayCodesTree(tree);
			} else {
				setError("Failed to fetch delay codes.");
			}
		} catch (error) {
			setError("An error occurred while fetching delay codes.");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (assetId) {
			fetchDelayCodes();
		}
	}, [assetId]);

	return (
		<div className={classes.treePickerWrapper}>
			{delayCodesTree && !isLoading ? (
				<TreePickerControlV2
					inputTitle="Code"
					title="Code"
					dataSource={delayCodesTree}
					value={value || 0}
					onSelect={(val, descr) =>
						onChange(val, delayCodes.find((d) => d.DelayCodeId === val)!)
					}
					selectBranch={true}
					selectActive={false}
					selectInternal={false}
				/>
			) : (
				<TextField
					disabled={true}
					value={value}
					InputProps={{
						endAdornment: isLoading ? <CircularProgress size={20} /> : null,
					}}
					label="Code"
					variant="outlined"
					style={{ cursor: "progress" }}
					className={selectorClasses.inputBase}
				/>
			)}

			{error && <span style={{ color: "red" }}>{error}</span>}
		</div>
	);
};
