import { LazyLoading } from "@/controls/LazyLoading";
import { AssetsFilter } from "@/controls/assetsFilter/AssetsFilter";
import { DelayCategorySelector } from "@/controls/selectors/DelayCategorySelector";
import { DelayCodePicker } from "@/controls/selectors/DelayCodePicker";
import { useDelayCodes } from "@/pages/delays/codes/components/hooks/useDelayCodes";
import {
	ErrorModal,
	MesfModal,
	TimeService,
	useUserContext,
} from "@dexteel/mesf-core";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	TextField,
	makeStyles,
} from "@material-ui/core";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DelayCode } from "../../models/Delay";
import { useDelaysManagerContext } from "../context/DelaysManagerContext";
import { DelayByShift } from "../models/DelayByShift";
import {
	justifyDelay,
	upsertDelay,
} from "../repositories/DelaysManagerRepository";
import { useSelectorStyles } from "../styles/selectorStyles";

const useStyles = makeStyles(() => ({
	dateTimePicker: {},
	"@global": {
		"*::-webkit-scrollbar": {
			width: "10px",
			background: "#fff",
		},
		"*::-webkit-scrollbar-thumb": {
			backgroundColor: "	#C8C8C8",
		},
		"*::-webkit-scrollbar-track": {
			borderRadius: "10px",
		},
		"*::-webkit-scrollbar:horizontal": {
			height: "10px",
		},
	},
	infoLabel: {
		fontSize: 12,
		color: "#757575",
	},
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
	treePickerWrapper: {
		"& .MuiGrid-root.MuiGrid-container": {
			"& .MuiGrid-item": {
				"& .MuiFormControl-root": {
					"& .MuiInputBase-root": {
						"& .MuiOutlinedInput-notchedOutline": {
							border: "2px solid #3F51B5",
						},
					},
				},
			},
		},
	},
	commentsInput: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
		"& .MuiOutlinedInput-input": {
			height: "60px !important",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	refreshData: Function;
	delay: DelayByShift | null;
	ifJustified: boolean;
};
export const UpsertJustifyDelay = ({
	show,
	onHide,
	refreshData,
	delay,
	ifJustified,
}: Props) => {
	const classes = useStyles();
	const selectorClasses = useSelectorStyles();

	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [isNew, setIsNew] = useState(false);
	const [title, setTitle] = useState("");
	const [duration, setDuration] = useState<string | number>("");
	const [isLoadingCategories, setIsLoadingCategories] = useState(false);
	const {
		state: { defaultAreaId },
	} = useUserContext();
	const {
		actions: { setNotificationMessage, setErrorMessage, setSelectedDelay },
	} = useDelaysManagerContext();

	const { loadFilterDelays } = useDelayCodes();

	const {
		register,
		setValue,
		control,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm<DelayByShift>({
		defaultValues: {
			AssetId: delay?.AssetId,
		},
	});

	const comments = watch("Comments");
	const start = watch("Start");
	const end = watch("End");
	const assetId = watch("AssetId");

	const onChangeDelayCodeId = (value: number | null, delayCode: DelayCode) => {
		setValue("DelayCodeId", value);
		setValue("DelayCategoryId", delayCode.DelayCategoryId);
	};

	const onSubmit: SubmitHandler<DelayByShift> = async (data: DelayByShift) => {
		setIsSubmitLoading(true);
		if (ifJustified) {
			const response = await justifyDelay(
				data.DelayId!,
				data.DelayCodeId ?? null,
				data.DelayCategoryId ?? null,
				data.Comments ?? null,
			);
			if (response.ok) {
				setNotificationMessage("Delay was justified successfully");
				setSelectedDelay(null);
				await refreshData();
			} else {
				setErrorMessage(response.message);
			}
		} else {
			const response = await upsertDelay(
				data.DelayId === 0 ? null : data.DelayId,
				data.AssetId,
				data.Start,
				data.End,
				data.DelayCodeId,
				data.DelayCategoryId,
				data.Comments,
			);
			if (response.ok) {
				setNotificationMessage(
					"Delay was " + (isNew ? "created" : "edited") + " successfully",
				);
				setSelectedDelay(null);
				await refreshData();
			} else {
				setErrorMessage(response.message);
			}
		}
		setIsSubmitLoading(false);
		onHide(true);
	};

	useEffect(() => {
		if (show) {
			setIsLoading(true);
			setIsSubmitLoading(false);

			(async () => {
				loadFilterDelays();
				if (delay) {
					if (delay?.DelayId === 0) {
						reset();
						setIsNew(true);
					} else {
						setIsNew(false);
					}
					if (ifJustified) {
						setTitle("JUSTIFY DELAY");
					} else {
						setTitle(delay.DelayId === 0 ? "NEW DELAY" : "EDIT DELAY");
					}
					reset({
						AssetId:
							delay.AssetId ??
							(defaultAreaId && defaultAreaId != 10
								? Number(defaultAreaId)
								: null),
						DelayCodeId: delay.DelayCodeId,
						DelayId: delay.DelayId,
						Start:
							delay.Start === null ? null : moment.utc(delay.Start).toDate(),
						End: delay.End === null ? null : moment.utc(delay.End).toDate(),
						DelayCategoryId: delay.DelayCategoryId,
						Comments: delay.Comments,
						IsManual: delay.IsManual,
						DelayCodeName: delay.DelayCodeName,
					});
				}
			})();

			setIsLoading(false);
		}
	}, [show, delay]);

	useEffect(() => {
		if (start && end) {
			const duration = moment.duration(moment(end).diff(moment(start)));
			const minutes = Math.floor(duration.asMinutes());
			const seconds = duration.seconds();
			setDuration(`${minutes}m ${seconds}s`);
		} else {
			setDuration("");
		}
	}, [start, end]);

	if (isLoadingCategories) return <LazyLoading />;
	return (
		<>
			<Grid container>
				<Grid item className={classes.dateTimePicker}>
					<MesfModal
						maxWidth="md"
						open={show}
						handleClose={() => {
							onHide(true);
						}}
						id="upsertDelay"
						title={title}
					>
						<form onSubmit={handleSubmit(onSubmit)}>
							<MesfModal.Content
								style={{ padding: "15px 30px" }}
								enterKeyHint={""}
							>
								{isLoading && (
									<Grid
										container
										justifyContent="center"
										alignItems="center"
										style={{ minHeight: "300px" }}
									>
										<LazyLoading />
									</Grid>
								)}
								{!isLoading && (
									<>
										<Grid
											container
											spacing={1}
											style={{ alignItems: "center" }}
										>
											<Grid item xs={4} md={4}>
												<Controller
													name="Start"
													control={control}
													rules={{
														required: "Start date is required",
														validate: {
															notAfterEnd: (value) => {
																const endDate = watch("End");
																if (value && endDate && value > endDate) {
																	return "Start date cannot be after End date.";
																}
																return true;
															},
															notInFuture: (value) => {
																if (value && value > new Date()) {
																	return "Start date cannot be in the future.";
																}
																return true;
															},
														},
													}}
													render={({ field }) => (
														<DateTimePicker
															disabled={ifJustified}
															label="Delay Start"
															timezone={TimeService.getInstance().getServerTimeZone()}
															format="MM/DD/YYYY HH:mm:ss"
															ampm={false}
															value={moment(field.value)}
															onChange={field.onChange}
															inputRef={field.ref}
															slotProps={{
																textField: {
																	variant: "outlined",
																	size: "small",
																	error: !!errors.Start,
																},
															}}
														/>
													)}
												/>
												{errors.Start && (
													<p style={{ color: "red" }}>{errors.Start.message}</p>
												)}
											</Grid>
											<Grid item xs={4} md={4}>
												<Controller
													name="End"
													control={control}
													rules={{
														validate: {
															notBeforeStart: (value) => {
																const startDate = watch("Start");
																if (value && startDate && value < startDate) {
																	return "End date cannot be before Start date.";
																}
																return true;
															},
															notInFuture: (value) => {
																if (value && value > new Date()) {
																	return "End date cannot be in the future.";
																}
																return true;
															},
															notSameAsStart: (value) => {
																const startDate = watch("Start");
																if (
																	value &&
																	startDate &&
																	moment(value).isSame(moment(startDate))
																) {
																	return "End date cannot be the same as Start date.";
																}
																return true;
															},
														},
													}}
													render={({ field }) => (
														<DateTimePicker
															disabled={ifJustified}
															label="Delay End"
															format="MM/DD/YYYY HH:mm:ss"
															ampm={false}
															timezone={TimeService.getInstance().getServerTimeZone()}
															value={moment(field.value)}
															onChange={field.onChange}
															inputRef={field.ref}
															slotProps={{
																textField: {
																	variant: "outlined",
																	size: "small",
																	error: !!errors.End,
																},
															}}
														/>
													)}
												/>
												{errors.End && (
													<p style={{ color: "red" }}>{errors.End.message}</p>
												)}
											</Grid>
											<Grid item xs={4} md={4} style={{ marginBottom: "3px" }}>
												<TextField
													label="Duration (min)"
													disabled
													variant="outlined"
													fullWidth
													value={duration}
													margin="dense"
													autoComplete="off"
												/>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={1}
											style={{ alignItems: "center", marginTop: "12px" }}
										>
											<Grid item xs={4} md={4}>
												<Controller
													name="AssetId"
													control={control}
													rules={{
														validate: (val) => {
															if (val == 14)
																return "Choose an option to continue";
															return val ? true : "Asset is required";
														},
													}}
													render={({ field }) => (
														<AssetsFilter
															value={field.value}
															onChange={field.onChange}
															onlyAssets
															title="Asset"
															nullValue=""
															disabled={ifJustified}
															customStyles={{
																height: "52px",
																border: ifJustified
																	? ""
																	: `2px solid ${errors.AssetId ? "red" : "#3F51B5"}`,
															}}
														/>
													)}
												/>
												{errors.AssetId && (
													<span style={{ color: "red" }}>
														{errors.AssetId.message}
													</span>
												)}
											</Grid>
											<Grid item xs={4} md={4} style={{ marginBottom: "3px" }}>
												<Controller
													name="DelayCodeId"
													control={control}
													render={({ field }) => (
														<DelayCodePicker
															key={`DelayCodeId-${assetId}`}
															assetId={assetId}
															value={field.value}
															disabled={!assetId}
															onChange={(
																value: number | null,
																delayCode: DelayCode,
															) => {
																onChangeDelayCodeId(value, delayCode);
															}}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={4} md={4}>
												<Controller
													name="DelayCategoryId"
													control={control}
													render={({ field }) => (
														<DelayCategorySelector
															value={field.value}
															onChange={field.onChange}
															assetId={assetId}
															error={errors.DelayCategoryId?.message || ""}
														/>
													)}
												/>
											</Grid>
										</Grid>
										<Grid item md={12} xs={12} style={{ marginTop: "12px" }}>
											<Controller
												name="Comments"
												control={control}
												render={({ field: { value, onChange } }) => (
													<TextField
														label="Comments"
														className={classes.commentsInput}
														variant="outlined"
														style={{ margin: "8px 0" }}
														maxRows={4}
														fullWidth
														margin="dense"
														multiline
														autoComplete="off"
														value={value ?? ""}
														onChange={(e) => {
															if (e.target.value.length <= 200) onChange(e);
														}}
													/>
												)}
											/>
											<Grid container style={{ marginBottom: 18 }}>
												<Grid item>
													{(comments?.length as number) >= 1 &&
														(comments?.length as number) !== 200 && (
															<span
																className={classes.infoLabel}
																style={{ marginLeft: 5 }}
															>{`${comments?.length}/200 max.`}</span>
														)}
													{(comments?.length as number) == 200 && (
														<span
															className={classes.infoLabel}
															style={{ marginLeft: 5 }}
														>
															Max. 200
														</span>
													)}
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={6} md={6}>
											<FormControlLabel
												control={
													<Checkbox
														{...register("IsManual")}
														color="primary"
														checked={isNew || delay?.IsManual}
														disabled={true}
													/>
												}
												label="Manual Delay"
											/>
										</Grid>
									</>
								)}
							</MesfModal.Content>
							<MesfModal.Actions
								style={{ padding: "20px 30px 30px" }}
								enterKeyHint={""}
							>
								<Grid container spacing={2} justifyContent="flex-end">
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											variant="contained"
											color="default"
											onClick={() => onHide(true)}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item md={3} xs={12} style={{ margin: 0 }}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading && <CircularProgress size="1rem" />
											}
											disabled={isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</MesfModal.Actions>
						</form>
					</MesfModal>
				</Grid>
			</Grid>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
