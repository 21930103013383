import { makeStyles } from "@material-ui/core";

export const useSelectorStyles = makeStyles(() => ({
	inputBase: {
		"& .MuiOutlinedInput-root": {
			height: "54px",
			"& .MuiOutlinedInput-notchedOutline": {
				border: "2px solid #3F51B5",
			},
			"&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
				border: "1px solid rgba(0, 0, 0, 0.23)",
			},
		},
	},
}));
