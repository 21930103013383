import { MESApiService, spParameter } from "@dexteel/mesf-core";

export const updateAsset = async (
	AssetIds: (number | string)[],
	ParentAssetId: number,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "AssetIds", value: AssetIds.join(",") });
	parameters.push({ name: "ParentAssetId", value: ParentAssetId });

	return await apiService.callV2("[MES].[UpdateAsset]", parameters);
};

export const insertAsset = async (
	AssetName: string,
	ParentAssetId: number,
	CanBeDefaultAsset: boolean | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "AssetName", value: AssetName });
	parameters.push({ name: "ParentAssetId", value: ParentAssetId });
	parameters.push({ name: "CanBeDefaultAsset", value: CanBeDefaultAsset });

	return await apiService.callV2("[MES].[InsertAsset]", parameters);
};

export const UpdateAssetData = async (
	AssetId: number,
	AssetName: string,
	ParentAssetId: number,
	IsAsset: boolean,
	CanBeDefaultAsset: boolean | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "AssetId", value: AssetId });
	parameters.push({ name: "AssetName", value: AssetName });
	parameters.push({ name: "ParentAssetId", value: ParentAssetId });
	parameters.push({ name: "IsAsset", value: IsAsset });
	parameters.push({ name: "CanBeDefaultAsset", value: CanBeDefaultAsset });

	return await apiService.callV2("[MES].[UpdateAssetData]", parameters);
};

export const removeAsset = async (AssetId: number, IsActive: boolean) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "AssetId", value: AssetId });
	parameters.push({ name: "IsActive", value: IsActive });

	return await apiService.callV2("[MES].[RemoveAsset]", parameters);
};

export const getAssets = async () => {
	const apiService = new MESApiService();

	return await apiService.callV2("[MES].[GetAssets]", []);
};
