import { ErrorModal, useContextMenuMESF } from "@dexteel/mesf-core";
import { Button, Grid, IconButton, makeStyles } from "@material-ui/core";
import { Comment, Edit } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import {
	GetContextMenuItemsParams,
	GridApi,
	GridReadyEvent,
	ICellRendererParams,
	MenuItemDef,
	RowClassParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { replace, useSearchParams } from "react-router-dom";
import { useGridDefinitions } from "../../../../controls/ag-grid/components/GridDefinitions";
import { ConfirmationDialogRaw } from "../../../../controls/modals/ConfirmationDialogRaw";
import { SplitDelays } from "../../../delays/SplitDelays";
import { useCatalogCategoryContext } from "../../../delays/categories/context/CategoriesContext";
import { getCatalogCategories } from "../../../delays/categories/repositories/CatalogCategoriesRepository";
import { useSearchDelays } from "../../../delays/codes/hooks/useSearchDelays";
import { useDelaysManagerContext } from "../context/DelaysManagerContext";
import { useDelaysOptionFunctions } from "../hooks/useDelaysOptionFunctions";
import { DelayByShift } from "../models/DelayByShift";
import { deleteDelay } from "../repositories/DelaysManagerRepository";
import { useGridStyles } from "../styles/gridStyles";
import { UpsertJustifyDelay } from "./UpsertJustifyDelay";
import { useTableData } from "./hooks";

const useStyles = makeStyles((theme) => ({
	unclassifiedRow: {
		"&.ag-row": {
			backgroundColor: "#E8C8C8 !important",
		},
		"&.ag-row:hover": {
			backgroundColor: "#E0B0B0 !important",
		},
		"&.ag-row-selected": {
			backgroundColor: "#D8A8A8 !important",
		},
	},
}));

type Props = {
	loadingGrid: boolean;
	rows: DelayByShift[];
	refreshData: Function;
};

export const DelaysManagerTable = ({
	loadingGrid,
	rows,
	refreshData,
}: Props) => {
	const classes = useGridStyles();
	const styleClasses = useStyles();

	const gridRef = useRef<AgGridReact>(null);
	const { columns } = useTableData({});

	const [isLoadingCategories, setIsLoadingCategories] =
		useState<boolean>(false);

	const [searchParams, setSearchParams] = useSearchParams();

	const { state, actions } = useDelaysManagerContext();

	const {
		state: {
			selectedDelay,
			anchorPoint: { x, y },
			errorMessage,
		},
		actions: {
			setSelectedDelay,
			setIsLoadingBackground,
			setNotificationMessage,
			setErrorMessage,
		},
	} = useDelaysManagerContext();
	const [error, setError] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [gridAPI, setGridAPI] = useState<GridApi | null>(null);

	const {
		createData,
		editData,
		deleteData,
		justifyData,
		getMenuOptions,
		showDeleteModal,
		showEditModal,
		showJustifyModal,
		messageDeleteModal,
		setShowDeleteModal,
		setShowEditModal,
		setMessageDeleteModal,
		setShowJustifyModal,
		showSplitModal,
		setShowSplitModal,
	} = useDelaysOptionFunctions();

	const { searchDelays } = useSearchDelays({
		setSearchError: setError,
		setAllDelayLoading: setIsLoading,
	});

	const {
		actions: { setCategories },
	} = useCatalogCategoryContext();

	const searchCategories = async (delayAreaAssetId: number | undefined) => {
		setIsLoadingCategories(true);
		const res = await getCatalogCategories(delayAreaAssetId);
		if (res.ok) {
			setCategories(res.data.tables[0].rows);
		} else {
			setError(res.message);
		}
		setIsLoadingCategories(false);
	};

	const { showContextMenu, registerConfig } = useContextMenuMESF();

	const getRowStyle = (params: RowClassParams) => {
		if (!params.data.IsActive || params.data.IsMicroDelay) {
			return { background: "#E8EBEB" };
		}
		if (!params.data.DelayCodeName || !params.data.DelayCategoryName) {
			return { background: "#E8C8C8" };
		}
	};

	const getRowClass = (params: RowClassParams) => {
		if (!params.data.DelayCodeName || !params.data.DelayCategoryName) {
			return styleClasses.unclassifiedRow;
		}
		return "";
	};

	const onGridReady = (params: GridReadyEvent) => {
		setGridAPI(params.api);
	};

	const onRowSelected = (e: any) => {
		if (e.node.selected) {
			setSearchParams({ delayId: e.data.DelayId }, { replace: true });
		}
	};

	const handleDeleteDelay = async (value?: string) => {
		setShowDeleteModal(false);
		setMessageDeleteModal("");
		if (value !== "OK") return;
		if (selectedDelay === null) return;
		setIsLoadingBackground(true);
		const resp = await deleteDelay(selectedDelay.DelayId);
		if (resp.ok) {
			setIsLoadingBackground(false);
			setNotificationMessage("Delay deleted successfully");
			setSelectedDelay(null);
			await refreshData();
		} else {
			setIsLoadingBackground(false);
			setErrorMessage(resp.message);
		}
	};

	const { columnSimpleDefaults, columnTypes } = useGridDefinitions({
		OnEdit: editData,
	});

	columnTypes["optionsButton"] = {
		field: "options",
		headerName: "",
		cellRenderer: (params: ICellRendererParams) => {
			return (
				<div className={classes.buttons}>
					<IconButton
						aria-label="justify-icon"
						title="Justify"
						style={{ cursor: "pointer" }}
						onClick={() => justifyData(params.data)}
					>
						<Comment
							fontSize="inherit"
							color="primary"
							style={{ cursor: "pointer" }}
						/>
					</IconButton>
					{params.data.IsManual && (
						<IconButton
							aria-label="delete-icon"
							style={{ cursor: "pointer" }}
							title="Delete"
							onClick={() => {
								deleteData(params.data);
							}}
						>
							<DeleteIcon
								fontSize="inherit"
								color="secondary"
								style={{ cursor: "pointer" }}
							/>
						</IconButton>
					)}

					<IconButton
						aria-label="Options"
						title="Options"
						style={{ cursor: "pointer" }}
						onClick={(e) =>
							showContextMenu(e, params.data, "delaysManagerContext")
						}
					>
						<ListIcon
							fontSize="inherit"
							color="primary"
							style={{ cursor: "pointer" }}
						/>
					</IconButton>
				</div>
			);
		},
		cellClass: classes.buttons,
	};

	useEffect(() => {
		if (
			loadingGrid &&
			gridRef.current !== null &&
			gridRef.current.api !== undefined
		) {
			gridRef.current!.api.showLoadingOverlay();
		} else if (gridRef.current !== null && gridRef.current.api !== undefined) {
			gridRef.current!.api.hideOverlay();
		}
		searchDelays(11);
		searchCategories(11);
	}, [loadingGrid]);

	const getContextMenuItems = (
		params: GetContextMenuItemsParams,
	): (string | MenuItemDef)[] => {
		const data = params.node?.data;

		if (data) {
			params.api.deselectAll();
			params.node?.setSelected(true);
			setSelectedDelay(data);
			showContextMenu(event as any, data, "delaysManagerContext");
		}

		const addNewDelay: (string | MenuItemDef)[] = [];

		if (!data) {
			addNewDelay.push({
				name: "New Manual Delay",
				icon: renderToStaticMarkup(<PlaylistAddIcon />),
				action: () => setShowEditModal(true),
			});
		}
		return addNewDelay;
	};

	useEffect(() => {
		registerConfig({
			id: "delaysManagerContext",
			getOptions: getMenuOptions,
		});
	}, []);

	const delayId = searchParams.get("delayId");
	useEffect(() => {
		if (gridAPI && !state.isLoadingGrid && rows.length > 0) {
			if (delayId) {
				const node = gridAPI?.getRowNode(delayId);
				if (node) {
					node.setSelected(true, true);
					gridAPI.ensureNodeVisible(node, "top");
				} else {
					setSearchParams({}, { replace: true });
				}
			}
		}
	}, [gridAPI, state.isLoadingGrid, rows, delayId]);

	return (
		<div onContextMenu={(e) => e.preventDefault()}>
			<div>
				<Grid container className={classes.root}>
					<Grid
						item
						md={12}
						xs={12}
						style={{
							border: "4px solid #ccc",
							borderRadius: 10,
							padding: 10,
							height: "calc(100vh - 300px)",
							minHeight: "calc(100vh - 300px)",
							margin: "20px 0 10px",
						}}
						className={"ag-theme-balham"}
					>
						<ConfirmationDialogRaw
							title="Confirmation"
							message={messageDeleteModal}
							open={showDeleteModal}
							onClose={handleDeleteDelay}
						/>

						<UpsertJustifyDelay
							show={showEditModal || showJustifyModal}
							onHide={() => {
								setShowEditModal(false);
								setShowJustifyModal(false);
							}}
							refreshData={refreshData}
							delay={selectedDelay}
							ifJustified={showJustifyModal}
						/>
						<SplitDelays
							show={showSplitModal}
							onHide={() => setShowSplitModal(false)}
							refreshData={refreshData}
							delay={selectedDelay}
						/>
						<AgGridReact
							ref={gridRef}
							rowSelection="single"
							pagination={false}
							animateRows={true}
							rowHeight={35}
							headerHeight={35}
							singleClickEdit={true}
							onCellDoubleClicked={(e) => justifyData(e.data)}
							tooltipShowDelay={0}
							tooltipHideDelay={2000}
							rowData={rows}
							columnDefs={columns}
							columnTypes={columnTypes}
							getRowClass={getRowClass}
							defaultColDef={columnSimpleDefaults}
							getRowStyle={getRowStyle}
							getRowId={(param) => param.data.DelayId}
							getContextMenuItems={getContextMenuItems}
							onRowSelected={onRowSelected}
							onGridReady={onGridReady}
						/>
						<Grid container justifyContent="flex-end">
							<Grid item md={2} xs={12} className={classes.btnModal}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									style={{ margin: "1.5rem 0px 0px" }}
									onClick={() => {
										createData();
									}}
								>
									NEW DELAY
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
			<ErrorModal error={errorMessage} onHide={() => setErrorMessage("")} />
		</div>
	);
};
