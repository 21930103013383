import { isNil } from "lodash";

export const statusCodeFormatter = (statusCode: string) => {
	if (isNil(statusCode)) return " ";
	switch (statusCode) {
		case "S":
			return "Scheduled";
		case "M":
			return "Melting";
		case "T":
			return "Tapped";
		case "O":
			return "OpenInCaster";
		case "C":
			return "InCaster";
		case "R":
			return "Returned";
		case "F":
			return "Finished";
		case "E":
			return "EAF";
		case "P":
			return "Closed";
		case "U":
			return "ReadyToCaster";
		default:
			return " ";
	}
};
