import { Suspense } from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import MeltShopDashboard from "../../pages/meltshop-dashboard";
import HeatsDataEntry from "../../pages/meltshop/heats";
import LadleStatus from "../../pages/meltshop/ladle-status";
import MonthReport from "../../pages/meltshop/month-report";

export const MeltshopRoutes = [
	<Route
		path="/meltshop-dashboard"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<MeltShopDashboard />
			</Suspense>
		}
	/>,
	<Route
		path="/meltshop-dashboard/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<MeltShopDashboard />
			</Suspense>
		}
	/>,
	<Route
		path="/meltshop-heats"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<HeatsDataEntry />
			</Suspense>
		}
	/>,
	<Route
		path="/meltshop-heats/:shiftId"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<HeatsDataEntry />
			</Suspense>
		}
	/>,
	<Route
		path="/month-report"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<MonthReport />
			</Suspense>
		}
	/>,
	<Route
		path="/ladle-status"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<LadleStatus />
			</Suspense>
		}
	/>,
];
