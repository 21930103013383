import { DropOptions, NodeModel, isAncestor } from "@minoru/react-dnd-treeview";
import { useEffect } from "react";
import { FileProperties } from "../../../models/FileProperties";
import { reorderDelays } from "../../repositories/ConfigurationDelayRepository";

type Props = {
	delay: NodeModel<FileProperties>[];
	selectedNodes: NodeModel<FileProperties>[];
	isCtrlPressing: boolean;
	setIsCtrlPressing: Function;
	setSelectedNodes: Function;
	setIsDragging: Function;
	onDelayUpdateEnd: (
		result: { ok: true } | { ok: false; message: string },
	) => void;
	onDelayUpdateStart?: () => void;
};

export const useDelayActions = ({
	delay,
	selectedNodes,
	setSelectedNodes,
	isCtrlPressing,
	setIsDragging,
	setIsCtrlPressing,
	onDelayUpdateEnd,
	onDelayUpdateStart = () => {},
}: Props) => {
	useEffect(() => {
		const handleKeyDown = (e: any) => {
			if (e.key.toLowerCase() === "escape") {
				setSelectedNodes([]);
			} else if (e.ctrlKey || e.metaKey) {
				setIsCtrlPressing(true);
			}
		};

		const handleKeyUp = (e: any) => {
			if (e.key.toLowerCase() === "control" || e.key.toLowerCase() === "meta") {
				setIsCtrlPressing(false);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		window.addEventListener("keyup", handleKeyUp);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
			window.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	const handleDrop = async (
		_: NodeModel<FileProperties>[],
		options: DropOptions<FileProperties>,
	) => {
		let { dropTargetId } = options;

		if (dropTargetId == 0) {
			dropTargetId = 1;
		}

		onDelayUpdateStart();
		const result = await reorderDelays(
			selectedNodes.map((cn) => cn.id),
			dropTargetId as number,
		);

		if (result.ok) {
			setSelectedNodes([]);
		}

		onDelayUpdateEnd(result);
	};

	const handleSingleSelect = (node: NodeModel<FileProperties>) => {
		setSelectedNodes([node]);
	};

	const handleMultiSelect = (clickedNode: NodeModel<FileProperties>) => {
		const selectedIds = selectedNodes.map((n) => n.id);

		if (selectedIds.includes(clickedNode.id)) {
			return;
		}
		if (
			selectedIds.some((selectedId) =>
				isAncestor(delay, selectedId, clickedNode.id),
			)
		) {
			return;
		}

		let updateNodes = [...selectedNodes];

		updateNodes = updateNodes.filter((selectedNode) => {
			return !isAncestor(delay, clickedNode.id, selectedNode.id);
		});

		updateNodes = [...updateNodes, clickedNode];
		setSelectedNodes(updateNodes);
	};

	const handleCtrlClick = (
		e: React.MouseEvent,
		node: NodeModel<FileProperties>,
	) => {
		if (e.ctrlKey || e.metaKey) {
			handleMultiSelect(node);
		} else {
			handleSingleSelect(node);
		}
	};

	const handleDragStart = (node: NodeModel<FileProperties>) => {
		const isSelectedNode = selectedNodes.some((n) => n.id === node.id);
		setIsDragging(true);

		if (!isCtrlPressing && isSelectedNode) {
			return;
		}

		if (!isCtrlPressing) {
			setSelectedNodes([node]);
			return;
		}

		if (!selectedNodes.some((n) => n.id === node.id)) {
			setSelectedNodes([...selectedNodes, node]);
		}
	};

	const handleDragEnd = () => {
		setIsDragging(false);
		setIsCtrlPressing(false);
	};
	return { handleDrop, handleCtrlClick, handleDragEnd, handleDragStart };
};
